import React from "react";
import { useState } from "react";

import "./main.less";
import { authService } from "../../../../api";
import { Button, LinkButton } from "../../../common/button/Button";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";
import { useSelector } from "../../../../hooks";

export const Complete = (args: WizardContentProps) => {
  const oneTimeCode = useSelector((state) => state.auth.oneTimeCode),
    [consented, setConsented] = useState(false),
    { content } = args;

  return consented ? (
    <>
      <Header
        title={(isGeneral(content) && content.consentTitle) || undefined}
        collapsed={true}
      />
      <section className="privacy">
        <div className="highlight">
          <h2>Registrering</h2>
          <p>
            <strong>
              Takk for at du vil delta! På neste side logger du på appen med
              BankID.
            </strong>
          </p>
          <Button
            type="hoved"
            onClick={async () => {
              const result = await authService.getLoginUrl(
                oneTimeCode || "apekatt"
              );

              if (result !== null) window.location.href = result;
            }}
          >
            Gå videre
          </Button>
        </div>
      </section>
    </>
  ) : (
    <>
      <Header
        title={(isGeneral(content) && content.consentTitle) || undefined}
        collapsed={true}
      />
      <section className="privacy">
        <div className="highlight">
          <h2>Samtykkeerklæring</h2>
          <p>
            <strong>Samtykker du til å delta i forskningsprosjektet?</strong>
          </p>
          <Button type="hoved" onClick={() => setConsented(true)}>
            Ja, jeg ønsker å delta
            <br />i forskningsprosjektet
          </Button>
          <LinkButton
            to="/exit"
            onClick={(e) =>
              !window.confirm(
                "Er du sikker på at du vil avslutte registreringen?"
              ) && e.preventDefault()
            }
          >
            Nei, jeg ønsker ikke å delta
            <br />i forskningsprosjektet
          </LinkButton>
        </div>
        {args.children(true)}
      </section>
    </>
  );
};
