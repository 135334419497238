import React from "react";
import "./main.less";

export const Help = () => (
  <section className="help">
    <h2>Hjelp til å fullføre skole og utdanning</h2>
    <p>Det finnes hjelp som gjør det lettere å fullføre skole og utdanning.</p>
    <h3>Noen å snakke med</h3>
    <p>
      Hvis du synes det er vanskelig å følge undervisningen, bør du snakke med
      noen på skolen din. Du kan ha rett på tilpasset undervisning.
    </p>
    <p>
      Her finner du en oversikt over{" "}
      <a href="https://www.nav.no/opplysning-rad-veiledning">
        andre du kan ta kontakt med
      </a>
      .
    </p>
    <h3>Hjelpemidler fra NAV</h3>
    <p>
      Har du utfordringer med språk, helse eller funksjonsnedsettelser som gjør
      det vanskelig å fullføre skole eller utdanning? Da kan du ha rett på
      tilrettelegging fra NAV. Les mer om{" "}
      <a href="https://www.nav.no/no/person/hjelpemidler/hjelpemidler-og-tilrettelegging/hjelpemidler">
        hvilke hjelpemidler du kan søke om fra NAV
      </a>
      .
    </p>
    <h3>Lese og skrive</h3>
    <p>
      Har du problemer med å lese og skrive, kan du for eksempel søke om støtte
      til å kjøpe PC eller nettbrett. Les mer om{" "}
      <a href="https://www.nav.no/no/person/hjelpemidler/hjelpemidler-og-tilrettelegging/hjelpemidler/lese-og-skrive">
        hjelpemidler som gjør det lettere å lese og/eller skrive
      </a>
      .
    </p>
    <h3>Økonomisk støtte når du er syk</h3>
    <p>
      Blir du syk og ikke klarer å følge undervisningen, kan du ha rett til
      sykepenger eller arbeidsavklaringspenger fra NAV. Studielånet ditt kan
      også gjøres om til stipend i perioden du er syk.
    </p>
    <h3>Studielån fra Lånekassen kan gjøres om til stipend</h3>
    <p>
      Studielånet kan gjøres om til stipend i perioden du er syk, les mer om
      <a href="https://lanekassen.no/nb-NO/stipend-og-lan/sykdom">
        sykestipend på lanekassen.no
      </a>
      .
    </p>
    <h3>Sykepenger fra NAV</h3>
    <p>
      Har du hatt jobb ved siden av studiet i mer enn 4 uker før du ble syk og
      tjent mer enn 49 929 kroner, kan du ha rett til sykepenger fra NAV. Hvor
      mye du får, er avhengig av arbeidsinntekt og type arbeidsforhold.{" "}
      <a href="https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade/sykepenger">
        Les mer om sykepenger
      </a>
      .
    </p>
    <h3>Arbeidsavklaringspenger (AAP) fra NAV</h3>
    <p>
      Du kan ha rett til{" "}
      <a href="https://www.nav.no/aap">arbeidsavklaringspenger fra NAV</a> hvis
      du er over 18 år og har avsluttet studier på grunn av sykdom eller skade,
      eller trenger behandling for å kunne gjenoppta studier.
    </p>
    <p>
      Snakk med legen eller studiestedet ditt for mer informasjon om hva du har
      rett til.
    </p>
  </section>
);
