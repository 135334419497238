import { createAsyncThunk } from "@reduxjs/toolkit";

import { Progress } from "../types";
import { progressService as api } from "../api";

export const reportMood = createAsyncThunk<number, { mood: number }>(
  "progress/reportMood",
  async ({ mood }, { getState, rejectWithValue: reject }) => {
    const { chapter } = (getState() as { progress: Progress }).progress;

    return await api
      .put(chapter || 0, "reportMood", undefined, mood)
      .catch((err) => reject(err))
      .then(() => mood);
  }
);
