import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SanityIllustration } from "../sanity-client";

export const {
  actions: { setCache },
  reducer: cacheSliceReducer,
} = createSlice({
  name: "cache",
  initialState: {} as Record<string, [SanityIllustration]>,
  reducers: {
    setCache: (
      state,
      {
        payload: { id, result },
      }: PayloadAction<{ id: string; result: [SanityIllustration] }>
    ) => {
      state[id] = result;
    },
  },
});
