import classNames from "classnames";
import React from "react";

import "./main.less";
import { CoinDrop } from "./coinDrop/CoinDrop";
import { Ingress } from "./ingress/Ingress";
import { LinkButton } from "../button/Button";
import { Points } from "../points/Points";
import { Section } from "../../../types";
import { SettingsIcon } from "../icons/Icons";
import { useSelector } from "../../../hooks";

export interface Props {
  title?: string;
  description?: string;
  secondaryDescription?: string;
  homeButton?: boolean;
  ancillaryButtons?: boolean;
  collapsed?: boolean;
  milestone?: boolean;
  inSettings?: boolean;
  inGiftCard?: boolean;
  theme?: "light" | "dark";
}

export const Header = (props: Props) => {
  const { description = "", collapsed = false, theme = "dark" } = props,
    version = useSelector((state) => state.user.version),
    completed = useSelector((state) => state.progress.completed),
    content = useSelector((state) => state.content.sanity);

  return (
    <header className={classNames("header", theme, collapsed && "collapsed")}>
      {props.homeButton ? (
        <LinkButton type={collapsed ? "hoved" : "standard"} to="/chapters">
          Forside
        </LinkButton>
      ) : (
        <h1>{props.title}</h1>
      )}
      {props.ancillaryButtons && (
        <nav>
          {((button: React.ReactNode) =>
            props.milestone ? <CoinDrop>{button}</CoinDrop> : button)(
            <LinkButton
              to="/giftcard"
              compact
              type={props.inGiftCard ? "" : collapsed ? "hoved" : "standard"}
              style={{ pointerEvents: props.inGiftCard ? "none" : "all" }}
            >
              <Points
                inMilestone={props.milestone || false}
                headless={false}
                version={version}
                chapters={content[Section.CHAPTERS]}
                completed={completed}
              />
            </LinkButton>
          )}
          <LinkButton
            compact
            type={props.inSettings ? "" : collapsed ? "hoved" : "standard"}
            to="/settings"
            onClick={() => props.inSettings && window.history.back()}
          >
            {props.inSettings ? "Lukk" : <SettingsIcon />}
            <span className="sr-only">
              {props.inSettings ? "Lukk" : "Åpne"} innstillinger
            </span>
          </LinkButton>
        </nav>
      )}
      {!collapsed && (
        <Ingress
          ingress={description}
          secondaryIngress={props.secondaryDescription}
          theme={theme}
        />
      )}
    </header>
  );
};
