import React from "react";
import "./main.less";

export const Support = () => (
  <section className="support">
    <h2>Støtte til utdanning</h2>
    <h3>Videregående skole</h3>
    <p>
      Videregående legger grunnlaget for å søke videre studier, eller du kan
      velge yrkesfag og få fagbrev etter en periode i lære hos en bedrift.
      Offentlig videregående skole gir rett til stipend fra Lånekassen.
    </p>
    <h3>Valg av linje på videregående skole</h3>
    <p>
      På vilbli.no finner du en oversikt over{" "}
      <a href="https://www.vilbli.no">linjer du kan velge på videregående</a>.
    </p>
    <h3>Trenger du råd?</h3>
    <p>
      <a href="https://utdanning.no/tema/hjelp_og_veiledning/karrieresentre">
        Karrieresentre
      </a>{" "}
      rundt om i landet tilbyr veiledning til alle over 19 år. Veiledningen er
      gratis, og veilederne har taushetsplikt.
    </p>
    <h3>Bestemt deg?</h3>
    <p>
      Les om hvordan du{" "}
      <a href="https://utdanning.no/tema/soknad_og_opptak/slik_soker_du_videregaende">
        søker om opptak til videregående skole på utdanning.no
      </a>
      .
    </p>
    <h3>Videregående for deg under 25 år</h3>
    <p>
      Er du under 25 år og har ikke fullført grunnskole eller tilsvarende
      opplæring? Du har rett til 3 års videregående opplæring. Les mer om
      <a href="https://lanekassen.no/nb-NO/regelverk/tildeling/del2-kapittel8/ungdomsrett/">
        ungdomsrett på lanekassen.no
      </a>{" "}
      eller på{" "}
      <a href="https://www.vilbli.no/nb/nb/no/ungdomsrett-lovfestet-rett-for-ungdom/a/032955">
        vilbli.no
      </a>
      .
    </p>
    <p>Du må bruke hele retten innen utgangen av det året du fyller 24 år.</p>
    <p>
      Du har rett til{" "}
      <a href="https://utdanning.no/tema/hjelp_og_veiledning_nyttig_informasjon/oppfolgingstjenesten">
        hjelp fra oppfølgingstjenesten
      </a>{" "}
      frem til du er 21 år. Les mer om oppfølgingstjenesten der du bor på
      vilbli.no.
    </p>
    <h3>Videregående for deg over 25 år</h3>
    <p>Du kan få tilbud om gratis videregående opplæring hvis du</p>
    <ul>
      <li>er født i {new Date().getFullYear() - 25} eller tidligere</li>
      <li>har fullført grunnskole eller tilsvarende</li>
      <li>
        ikke har fullført videregående opplæring i Norge eller hjemlandet ditt
      </li>
    </ul>
    <p>
      Fylkeskommunen har ansvar for videregående opplæring. Les mer om
      <a href="https://utdanning.no/tema/hjelp_og_veiledning/videregaende_som_voksen">
        "videregående som voksen" på utdanning.no
      </a>
      .
    </p>
    <h3>Økonomisk støtte til videregående skole</h3>
    <p>Stipend til videregående skole</p>
    <p>
      Hos Lånekassen finner du informasjon om{" "}
      <a href="https://lanekassen.no/nb-NO/stipend-og-lan/norge/videregaende-skole/">
        stipend eller lån når du går på videregående skole
      </a>
      .
    </p>
    <h3>Barnebidrag for barn over 18 år</h3>
    <p>
      Hvis du ikke bor sammen med begge foreldrene dine, kan du ha krav på
      bidrag fra en eller begge foreldre. Les hvordan du søker om{" "}
      <a href="https://www.nav.no/barnebidrag">
        barnebidrag når du er over 18 år
      </a>
      .
    </p>
    <h3>Utdanningsstønad hvis du er aleneforsørger</h3>
    <p>
      Er du aleneforsørger for et barn og har rett til overgangsstønad fra NAV?
      Da kan du også ha rett til ekstra støtte hvis du trenger utdanning
      (utdanningsstønad). Les mer om{" "}
      <a href="https://www.nav.no/overgangsstonad-enslig">
        overgangsstønad og hvordan du søker
      </a>
      .
    </p>
    <h3>Ta opp fag og forbedre karakterer</h3>
    <p>
      Hvis du mangler fag eller vil forbedre karakterer fra videregående, kan du
      gjøre det som privatist. Les mer om å{" "}
      <a href="https://utdanning.no/tema/soknad_og_opptak/privatist">
        ta opp fag som privatist på utdanning.no
      </a>
      .
    </p>
  </section>
);
