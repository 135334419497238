import React from "react";
import { AboutSection } from "../onboarding/AboutSection";
import { AccessSection } from "../onboarding/AccessSection";
import { PrivacySection } from "../onboarding/PrivacySection";
import { TermsSection } from "../onboarding/TermsSection";

export const PrivacyOverview = (args: { backButton: () => void }) => (
  <section className="settingsPage">
    <h1>Samtykke</h1>
    {args.backButton()}
    <p>
      <em>Dette er det du har samtykket til da du registrerte deg i RØST:</em>
    </p>
    <AboutSection withPadding={false} />
    <AccessSection withPadding={false} includeNextPageShort={false} />
    <PrivacySection withPadding={false} />
    <TermsSection withPadding={false} />
  </section>
);
