import { Dispatch, SetStateAction, useEffect } from "react";

import { getAnswers } from "../../contentSlice";
import { Objective } from "../../../types";
import { SanityPage } from "../../../sanity-client";
import { useDispatch, useSelector } from "../../../hooks";
import { userService } from "../../../api";

export const useTasks = (
  args: SanityPage & {
    setDisableNext: Dispatch<SetStateAction<boolean>>;
    path: Objective;
  }
) => {
  const dispatch = useDispatch();
  const lockedTasks = useSelector((state) => state.content.meta.lockedTasks);
  const notLocked = (id: string) => lockedTasks.indexOf(id) === -1;
  const answers = useSelector((state) => state.content.meta.answers);
  const { setDisableNext, path } = args;
  const tasks =
    args[`assignments_${path}`] !== undefined && args[`assignments_${path}`]!;
  const submitPendingAnswers = () =>
    new Promise<void>((resolve) => {
      if (tasks && tasks.some((task) => notLocked(task.id)) && answers) {
        const copy = answers.slice();

        userService
          .addUserFeedbackData(
            tasks.reduce((data: Record<string, string>, task, index) => {
              data[task.id] = copy[index];

              return data;
            }, {})
          )
          .then(() => {
            dispatch(getAnswers(tasks.map((task) => task.id))).then(() =>
              resolve()
            );
          });
      } else resolve();
    });

  useEffect(() => {
    if (tasks) dispatch(getAnswers(tasks.map((task) => task.id)));
  }, [dispatch, path, tasks]);

  useEffect(() => {
    if (
      !tasks ||
      (tasks &&
        answers &&
        tasks.every(
          ({ type, limit }, index) =>
            type === "Flervelger" ||
            type === "Tid" ||
            type === "Tidspunkt" ||
            type === "Valgfri" ||
            type === "Lydvalg" ||
            (answers[index] &&
              answers[index] !== " " &&
              answers[index].length > 0 &&
              (answers[index].length <= parseInt(limit) ||
                (limit === "0" &&
                  (type === "Stjerner" ||
                    type === "Valg" ||
                    type === "Kjennskap" ||
                    type === "Lenkevalg" ||
                    type === "Flervelgeraktig" ||
                    type === "Velger"))))
        ))
    )
      setDisableNext(false);
    else if (tasks) setDisableNext(true);

    return () => setDisableNext(false);
  }, [answers, tasks, setDisableNext]);

  return { tasks, answers, lockedTasks, submitPendingAnswers };
};
