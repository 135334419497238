import { useEffect } from "react";

import { getProgress } from "../progressSlice";
import { Section } from "../../types";
import { useDispatch, useSelector } from "../../hooks";

export const usePoller = (name: string) => {
  const dispatch = useDispatch(),
    auth = useSelector((state) => state.auth),
    isLoggedIn = auth.user.id !== null;

  useEffect(() => {
    let poller: number;

    if (isLoggedIn) {
      dispatch(getProgress());
      if (name === Section.CHAPTERS) {
        poller = window.setInterval(() => {
          dispatch(getProgress());
        }, 15000);
      }
    }
    return () => clearInterval(poller);
  }, [name, isLoggedIn, dispatch]);
};
