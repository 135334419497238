import "./main.less";
import { Contact } from "../common/contact/Contact";
import { getGiftcards } from "../giftcardThunk";
import { getProgress } from "../progressSlice";
import { GiftCardCompact } from "./GiftCardCompact";
import { GiftCardItem } from "./GiftCardItem";
import { GiftCardStatus } from "./GiftCardStatus";
import { Header } from "../common/header/Header";
import { useDispatch, useSelector } from "../../hooks";
import React, { useEffect } from "react";
import { useTimeout } from "./useTimeout";

export const GiftCard = (args: { compact: boolean }) => {
  const { compact } = args,
    auth = useSelector((state) => state.auth),
    isLoggedIn = auth.user.id !== null,
    dispatch = useDispatch(),
    progress = useSelector((state) => state.progress),
    { giftcards, waitingForGiftcards } = progress,
    count = useTimeout(),
    pending = localStorage.getItem("pendingGiftcards");

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProgress());
      dispatch(getGiftcards());
    }
  }, [isLoggedIn, dispatch]);

  return compact ? (
    <GiftCardCompact progress={progress} />
  ) : (
    <>
      <Header collapsed homeButton ancillaryButtons inGiftCard />
      <section className="giftCardPage">
        <h1>Gavekort</h1>
        <p>
          Etterhvert som du fullfører de ulike modulene finner du gavekortene
          dine her. Gavekortene er universalgavekort som du kan benytte på{" "}
          <a href="https://www.gogift.com">gogift.com</a> til å handle varer
          eller opplevelser.
        </p>
        <ul>
          {(pending === null || pending === "false") && waitingForGiftcards ? (
            <GiftCardStatus />
          ) : (pending === null || pending === "false") &&
            giftcards?.length === 0 ? (
            <p style={{ textAlign: "center" }}>Du har ingen gavekort enda.</p>
          ) : giftcards?.length === 0 ? (
            <GiftCardStatus count={count}>
              Vi oppdaterer gavekortlisten din!
            </GiftCardStatus>
          ) : (
            giftcards?.map((props, index) =>
              props === null ? (
                <GiftCardStatus key={index} count={count}>
                  Et nytt gavekort blir opprettet!
                </GiftCardStatus>
              ) : (
                <GiftCardItem key={index} {...props} />
              )
            )
          )}
        </ul>
      </section>
      <Contact />
    </>
  );
};
