/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";

import { Button } from "../../../common/button/Button";
import { CDN_URL } from "../../../../constants";
import { SanityAudio } from "../../../../sanity-client";
import { SoundIcon } from "../../../common/icons/Icons";
import { Timeout } from "../../../../types";
import config from "../../../../sanity-config.json";

const audioElement = document.getElementById("audioPlayer") as HTMLAudioElement;

const play = (
  audio: SanityAudio | undefined,
  paused?: boolean | null,
  setPaused?: React.Dispatch<React.SetStateAction<boolean | null>>
) => {
  audioElement.muted = false;

  if (paused === null || audioElement.className === "reset") {
    audioElement.className = "";

    if (audioElement.querySelector("source") !== null) {
      const newAudioSource = audio
        ? `${CDN_URL}files/${config.projectId}/${
            config.dataset
          }/${audio.asset._ref.replace(/file-|-mp3/g, "")}.${"mp3"}`
        : "";

      audioElement.querySelector("source")!.src = newAudioSource;
    }
    audioElement.load();
    startPlayback();
  } else if (paused === false) {
    pausePlayback();
  } else if (paused) {
    startPlayback();
  }

  function startPlayback() {
    audioElement.play().catch((error) => console.log(error));
    setPaused && setPaused(false);
    localStorage.setItem("autoplay_sound", "true");
  }

  function pausePlayback() {
    audioElement.pause();
    setPaused && setPaused(true);
    localStorage.setItem("autoplay_sound", "false");
  }

  audioElement.addEventListener("ended", () => {
    setPaused && setPaused(null);
    window.scrollTo(0, 9999999);
  });
};

export const useAudio = (audio: SanityAudio | undefined, category?: string) => {
  const [autoPlay, setAutoPlay] = useState(false);
  const [paused, setPaused] = useState<boolean | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const className = classNames("audio", category === "Eksempel" && "adjusted");
  const onClick = () => audioElement && play(audio, paused, setPaused);

  function getDefaultSoundState(): boolean {
    return JSON.parse(localStorage.getItem("autoplay_sound") || "false");
  }

  useEffect(() => {
    const shouldAutoPlay = getDefaultSoundState();
    setAutoPlay(shouldAutoPlay);
  }, [audio, category]);

  useEffect(() => {
    let timeout: Timeout,
      activate = () => {
        audioElement &&
          audioElement.play().catch((error) => console.log(error));
        document.removeEventListener("touchstart", activate, false);
      };

    if (autoPlay) {
      setPaused(false);
      timeout = setTimeout(() => audioElement && play(audio), 1000);
      document.addEventListener("touchstart", activate, false);
    }

    return () => {
      clearTimeout(timeout);
      document.removeEventListener("touchstart", activate, false);
    };
  }, [audio, autoPlay]);

  useEffect(() => {
    if (!audioElement) return;

    audioElement.addEventListener("play", () => setIsPlaying(true));
    audioElement.addEventListener("pause", () => setIsPlaying(false));

    return () => {
      audioElement.removeEventListener("play", () => setIsPlaying(true));
      audioElement.removeEventListener("pause", () => setIsPlaying(false));
    };
  }, []);

  return () => (
    <Button {...{ className, onClick, type: "flat" }}>
      <SoundIcon />
      <span>Skru {isPlaying || (autoPlay && !paused) ? "av" : "på"} lyd</span>
    </Button>
  );
};
