import React from "react";
import "./main.less";
import { Header } from "../common/header/Header";
import { LinkButton } from "../common/button/Button";
import { ReactComponent as NotFoundIllustration } from "../common/graphics/404.svg";

export const NotFound = () => (
  <>
    <Header homeButton={true} collapsed={true} />
    <div className="notFound">
      <h1>404</h1>
      <p>Wops. Det gikk ikke helt etter planen...</p>
      <p>Vi finner ikke denne siden.</p>
      <NotFoundIllustration />
      <LinkButton type="hoved" to={"/"}>
        Tilbake til forsiden
      </LinkButton>
    </div>
  </>
);
