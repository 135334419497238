import { createElement, ReactNode, MutableRefObject } from "react";

import { rules, prepare, Rule } from "./rules";
import * as Autofill from "./features/common/autofill/Autofill";
import * as Quote from "./features/common/quote/Quote";
import { userService } from "./api";

const indexOf = (rule: Rule) => rules.indexOf(rule),
  key = () => ({ key: keyValue++ }),
  target = "_blank";
let color: Quote.Color, align: Quote.Alignment, keyValue: number;

export const format = (
  text: string,
  ref: boolean | MutableRefObject<Record<string, HTMLImageElement>>,
  lastRuleIndex?: number
): ReactNode => {
  let rule: Rule;

  if (lastRuleIndex !== undefined) {
    if (rules[lastRuleIndex + 1] !== undefined) rule = rules[lastRuleIndex + 1];
    else return text;
  } else {
    align = undefined;
    rule = rules[0];
    text = prepare(text);
    keyValue = 0;
  }

  return text.split(rule[0]).map((part: string, index: number) =>
    index % 2 === 0
      ? format(part, ref, indexOf(rule))
      : (() => {
          if (rule[1] === Autofill.Autofill) {
            let isTarget = part.indexOf("portal_target_for__") !== -1;

            return createElement(
              isTarget ? "span" : rule[1],
              { ...key(), children: null, [isTarget ? "id" : "value"]: part },
              isTarget ? "..." : part
            );
          } else if (rule[1] === Quote.Quote) {
            let mod = typeof ref === "boolean" && ref;

            align = align === undefined || align === "right" ? "left" : "right";
            color = Quote.nextQuoteColor(color);

            return createElement(
              rule[1],
              { ...key(), children: null, color, align, mod },
              format(part, ref, indexOf(rule))
            );
          } else if (rule[1] === "a" && part.split(" http").length > 1)
            return createElement(
              rule[1],
              {
                href: "#",
                // target,
                onClick: () => {
                  userService.addUserFeedbackData({
                    [`clicked_on_${"http" + part.split(" http")[1]}__feedback`]:
                      new Date().toISOString(),
                  });
                  window.open("http" + part.split(" http")[1], "_blank");
                },
                ...key(),
              },
              format(part.split(" http")[0], ref, indexOf(rule))
            );
          else if (rule[1] === "a" && part.split(" mailto:").length > 1)
            return createElement(
              rule[1],
              { href: "mailto:" + part.split(" mailto:")[1], target, ...key() },
              format(part.split(" mailto:")[0], ref, indexOf(rule))
            );
          else if (rule[1] === "img" && typeof ref !== "boolean")
            return createElement(rule[1], {
              ref: (el: HTMLImageElement) =>
                (ref.current[part.toLowerCase().replace(/ /g, "_")] = el),
              alt: part,
              ...key(),
            });

          return part.length > 0
            ? createElement(rule[1], key(), format(part, ref, indexOf(rule)))
            : null;
        })()
  );
};
