import React from "react";
import "./main.less";
import { Button } from "../../../common/button/Button";
import { completeChapter, reachPage } from "../../../progressSlice";
import { getRelativeId } from "../../../../utilities";
import { setFadeOut, setIds, setMovedBack } from "../../../contentSlice";
import { useDispatch, useSelector } from "../../../../hooks";
import { useSkip } from "./useSkip";

interface Props {
  map: Record<string, number>;
  order: number;
  chapterId: string;
  chapterOrder: number;
  disableNext: boolean;
  submitPendingAnswers: () => Promise<void>;
  skip: boolean;
}

export const Navigation = (args: Props) => {
  const { map, order, chapterId, chapterOrder, disableNext, skip } = args,
    dispatch = useDispatch(),
    movedBack = useSelector((state) => state.content.meta.movedBack),
    chapter = useSelector((state) => state.progress.chapter),
    next = getRelativeId(order, 1, map),
    prev = getRelativeId(order, -1, map);

  useSkip(skip, movedBack, chapterId, next, prev);

  return (
    <div className="navigation">
      <Button
        disabled={!prev}
        type="flat"
        onClick={() => {
          return (
            prev &&
            dispatch(setFadeOut()).then(() => {
              dispatch(setMovedBack(true));
              dispatch(setIds({ id: prev, parentId: chapterId }));
            })
          );
        }}
        children="Forrige"
      />
      <Button
        type="hoved"
        disabled={disableNext && !next}
        onClick={() => {
          disableNext
            ? alert("Vennligst fyll ut alle påkrevde felter for å gå videre.")
            : args.submitPendingAnswers().then(() =>
                dispatch(setFadeOut()).then(() => {
                  movedBack && dispatch(setMovedBack(false));
                  if (!next) dispatch(completeChapter());
                  else
                    chapter !== undefined &&
                      chapterOrder === chapter &&
                      dispatch(
                        reachPage({
                          chapter: chapterOrder,
                          pageNumber: order,
                        })
                      ); // TODO: Only send if page hasn't been reached yet
                  dispatch(
                    setIds({ id: next || "milestone", parentId: chapterId })
                  );
                })
              );
        }}
        children={next ? "Neste" : "Fullfør"}
      />
    </div>
  );
};
