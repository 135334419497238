import React from "react";
import "./main.less";

type Props = {
  withPadding?: boolean;
  children?: React.ReactNode;
};

export const PrivacySection = ({ withPadding = true, children }: Props) => (
  <section className={withPadding ? "privacy padded" : "privacy"}>
    <h2>
      Ditt personvern – hvordan vi oppbevarer og bruker opplysninger om deg
    </h2>
    <p>
      Ditt fødselsnummer og bostedskommune vil erstattes med en tilfeldig kode,
      som kun prosjektlederen har tilgang til. Ungdomsskolen du gikk på, vil bli
      omkodet av SSB med en tilfeldig kode før dataene blir overlevert. Siden
      personnummer lagres adskilt fra de øvrige data vil forskerne som jobber
      med data ikke vite hvem du er eller hva du har svart.
    </p>
    <p>
      Vi vil bare bruke opplysningene om deg til formålene vi har beskrevet
      tidligere. Oppfølgingstjenesten har ikke tilgang til informasjon om din
      deltakelse eller til alle de opplysningene du oppgir i RØST.
      Oppfølgingstjenesten vil kun motta anonymiserte data med svar på spørsmål
      om Oppfølgingstjenesten, knyttet til kommune og en tilfeldig kode for hver
      deltaker.
    </p>
    <p>
      Forskningsgruppen ved UiS oppbevarer og behandler alle opplysningene om
      deg strengt konfidensielt og i samsvar med personvernregelverket.
    </p>
    <p>
      Alle deltakere vil motta SMS (til det oppgitte telefonnummeret) med lenke
      til nyhetsbrev om prosjektet hvert 5. år frem til personopplysninger i
      prosjektet er slettet. Alle deltakere vil også motta en SMS med tilbud om
      å delta i en spørreundersøkelse ett år etter at de gjennomførte RØST.
    </p>
    <br />
    <h2>
      Hva skjer med opplysningene om deg når vi avslutter forskningsprosjektet?
    </h2>
    <p>Opplysningene slettes når prosjektet avsluttes i 2040.</p>
    {children}
  </section>
);
