import React from "react";
import classNames from "classnames";

import "./main.less";

interface Props {
  map: Record<string, number>;
  order: number;
}

export const Dots = ({ map, order }: Props) => (
  <span
    className={classNames("dots", Object.entries(map).length > 11 && "many")}
  >
    {Array.from({ length: Object.entries(map).length || 1 }).map(
      (value, index) => (
        <span
          key={index}
          className={classNames("dot", index <= order && "filled")}
        ></span>
      )
    )}
  </span>
);
