import React from "react";
import classNames from "classnames";

import { Mood } from "./moods";

const Animate = ({ from, to }: { from: string; to: string }) => (
    <animate attributeName="d" from={from} to={to} dur="0.2s" fill="freeze" />
  ),
  mouth = [
    "m 84,144 c 2,-6 21,-16 33,-4 -6,3 -8,5 -8,9",
    "m 94,143 c 0,0 12,-2 17,0 5,2 6,4 6,4",
    "m 85,146 c 3,3 7,4 11.538209,4.02805 C 101.07642,150.0561 104.4247,150 106,150",
    "m 67,124 c 0,0 10.07642,18.0561 33.53821,18.02805 C 124,142 133,124 133,124",
    "m 67,124 c 0,0 10.07642,18.0561 33.53821,18.02805 C 124,142 133,124 133,124",
  ],
  leftEye = [
    "m 72.153791,61.017087 c -1.317174,3.176978 -4.620646,5.114422 -8.369141,5.785156 -3.748495,0.670735 -7.743248,-0.150067 -9.533203,-1.582031 -0.862782,-0.690917 -2.122426,-0.550957 -2.8125,0.3125 -0.690917,0.862782 -0.550957,2.122426 0.3125,2.8125 1.513568,1.210854 3.399864,1.984318 5.453125,2.390625 -1.418119,1.655986 -2.203461,3.811499 -2.203125,6.046875 0,4.970563 3.80558,9 8.5,9 4.69442,0 8.5,-4.029437 8.5,-9 -3.61e-4,-2.86612 -1.289981,-5.560686 -3.472656,-7.255859 3.147598,-1.33678 5.915103,-3.589612 7.320312,-6.978516 0.423081,-1.020334 -0.0616,-2.19044 -1.373917,-2.468707 -1.312319,-0.278268 -2.018097,0.205914 -2.321395,0.937457 z",
    "m 63.370144,66.845374 c -0.476136,-0.12132 -0.976291,-0.198629 -1.465779,-0.155906 -0.69924,0.06103 -1.396593,0.266697 -2.023639,0.582091 -0.380641,0.191457 -0.707806,0.481428 -1.016051,0.775585 -0.297255,0.283669 -0.551076,0.611613 -0.792917,0.94379 -0.487166,0.669138 -0.991679,1.343627 -1.318696,2.10398 -0.90874,2.112931 -1.692354,4.101112 -1.723669,6.681434 -0.05478,4.513926 1.684045,11.537642 6.100739,11.786694 5.348824,0.301615 7.237469,-6.366846 7.619138,-11.057135 0.243223,-2.988932 -0.240477,-4.669685 -0.874387,-6.899591 -0.233265,-0.820558 -0.572513,-1.629775 -1.061928,-2.328488 -0.474424,-0.677312 -1.080023,-1.282227 -1.769536,-1.738737 -0.50345,-0.333323 -1.088177,-0.544634 -1.673275,-0.693717 z",
    "m 76.885005,73.825523 c -0.450411,-0.09305 -0.923545,-0.152346 -1.386587,-0.119578 -0.661462,0.04681 -1.321139,0.204553 -1.914307,0.446457 -0.360077,0.146845 -0.669566,0.369249 -0.961158,0.594864 -0.281194,0.21757 -0.521302,0.4691 -0.750078,0.723875 -0.460845,0.51322 -0.9381,1.030545 -1.24745,1.613727 -0.859644,1.620592 -1.600921,3.145502 -1.630544,5.124578 -0.05182,3.462126 1.593061,8.849229 5.771133,9.040249 5.059843,0.231335 6.84645,-4.883293 7.207498,-8.480687 0.230082,-2.292473 -0.227485,-3.581591 -0.827147,-5.291901 -0.220662,-0.629358 -0.541582,-1.250018 -1.004555,-1.785922 -0.448791,-0.51949 -1.021672,-0.983452 -1.673933,-1.33359 -0.476249,-0.255655 -1.029386,-0.417727 -1.582872,-0.532072 z",
    "m 63.370144,66.845374 c -0.476136,-0.12132 -0.976291,-0.198629 -1.465779,-0.155906 -0.69924,0.06103 -1.396593,0.266697 -2.023639,0.582091 -0.380641,0.191457 -0.707806,0.481428 -1.016051,0.775585 -0.297255,0.283669 -0.551076,0.611613 -0.792917,0.94379 -0.487166,0.669138 -0.991679,1.343627 -1.318696,2.10398 -0.90874,2.112931 -1.692354,4.101112 -1.723669,6.681434 -0.05478,4.513926 1.684045,11.537642 6.100739,11.786694 5.348824,0.301615 7.237469,-6.366846 7.619138,-11.057135 0.243223,-2.988932 -0.240477,-4.669685 -0.874387,-6.899591 -0.233265,-0.820558 -0.572513,-1.629775 -1.061928,-2.328488 -0.474424,-0.677312 -1.080023,-1.282227 -1.769536,-1.738737 -0.50345,-0.333323 -1.088177,-0.544634 -1.673275,-0.693717 z",
    "m 73.810917,68.262163 c -1.506947,-0.757362 -3.22159,-1.308772 -4.906552,-1.235364 -2.306477,0.100485 -4.644943,0.955726 -6.543869,2.268743 -1.937969,1.340014 -3.278028,3.431202 -4.524291,5.430748 -1.159368,1.86013 -2.028229,3.911659 -2.681967,6.00375 -0.443128,1.418095 0.06114,2.785406 1.110084,3.048506 1.552602,0.389427 2.737289,-0.523968 3.066425,-1.954231 1.019552,-4.430465 4.819518,-11.138559 9.608978,-10.746992 5.215307,0.426383 6.581349,5.100204 7.146875,7.900847 0.33772,1.672483 0.48285,3.226132 2.363988,3.287795 1.881137,0.06166 2.334987,-1.439118 2.176445,-2.66582 -0.3916,-3.02998 -1.016166,-5.599472 -2.579129,-7.878154 -1.031371,-1.50366 -2.60779,-2.641026 -4.236987,-3.459828 z",
  ],
  rightEye = [
    "m 133.84424,61.017087 c 1.31717,3.176978 4.62064,5.114422 8.36914,5.785156 3.74849,0.670735 7.74324,-0.150067 9.5332,-1.582031 0.86278,-0.690917 2.12243,-0.550957 2.8125,0.3125 0.69092,0.862782 0.55096,2.122426 -0.3125,2.8125 -1.51357,1.210854 -3.39987,1.984318 -5.45313,2.390625 1.41812,1.655986 2.20347,3.811499 2.20313,6.046875 0,4.970563 -3.80558,9 -8.5,9 -4.69442,0 -8.5,-4.029437 -8.5,-9 3.6e-4,-2.86612 1.28998,-5.560686 3.47266,-7.255859 -3.1476,-1.33678 -5.91511,-3.589612 -7.32032,-6.978516 -0.42308,-1.020334 0.0616,-2.19044 1.37392,-2.468707 1.31232,-0.278268 2.0181,0.205914 2.3214,0.937457 z",
    "m 134.47131,66.845374 c 0.47614,-0.12132 0.9763,-0.198629 1.46578,-0.155906 0.69924,0.06103 1.3966,0.266697 2.02364,0.582091 0.38064,0.191457 0.70781,0.481428 1.01605,0.775585 0.29726,0.283669 0.55108,0.611613 0.79292,0.94379 0.48717,0.669138 0.99168,1.343627 1.3187,2.10398 0.90874,2.112931 1.69235,4.101112 1.72367,6.681434 0.0548,4.513926 -1.68405,11.537642 -6.10074,11.786694 -5.34883,0.301615 -7.23747,-6.366846 -7.61914,-11.057135 -0.24323,-2.988932 0.24047,-4.669685 0.87438,-6.899591 0.23327,-0.820558 0.57252,-1.629775 1.06193,-2.328488 0.47443,-0.677312 1.08003,-1.282227 1.76954,-1.738737 0.50345,-0.333323 1.08818,-0.544634 1.67327,-0.693717 z",
    "m 143.6491,73.825523 c 0.45041,-0.09305 0.92355,-0.152346 1.38659,-0.119578 0.66146,0.04681 1.32114,0.204553 1.9143,0.446457 0.36008,0.146845 0.66957,0.369249 0.96116,0.594864 0.2812,0.21757 0.5213,0.4691 0.75008,0.723875 0.46085,0.51322 0.9381,1.030545 1.24745,1.613727 0.85964,1.620592 1.60092,3.145502 1.63054,5.124578 0.0518,3.462126 -1.59306,8.849229 -5.77113,9.040249 -5.05984,0.231335 -6.84645,-4.883293 -7.2075,-8.480687 -0.23008,-2.292473 0.22749,-3.581591 0.82715,-5.291901 0.22066,-0.629358 0.54158,-1.250018 1.00455,-1.785922 0.4488,-0.51949 1.02168,-0.983452 1.67394,-1.33359 0.47625,-0.255655 1.02938,-0.417727 1.58287,-0.532072 z",
    "m 134.47131,66.845374 c 0.47614,-0.12132 0.9763,-0.198629 1.46578,-0.155906 0.69924,0.06103 1.3966,0.266697 2.02364,0.582091 0.38064,0.191457 0.70781,0.481428 1.01605,0.775585 0.29726,0.283669 0.55108,0.611613 0.79292,0.94379 0.48717,0.669138 0.99168,1.343627 1.3187,2.10398 0.90874,2.112931 1.69235,4.101112 1.72367,6.681434 0.0548,4.513926 -1.68405,11.537642 -6.10074,11.786694 -5.34883,0.301615 -7.23747,-6.366846 -7.61914,-11.057135 -0.24323,-2.988932 0.24047,-4.669685 0.87438,-6.899591 0.23327,-0.820558 0.57252,-1.629775 1.06193,-2.328488 0.47443,-0.677312 1.08003,-1.282227 1.76954,-1.738737 0.50345,-0.333323 1.08818,-0.544634 1.67327,-0.693717 z",
    "m 135.83854,68.262163 c 1.50694,-0.757362 3.22159,-1.308772 4.90655,-1.235364 2.30648,0.100485 4.64494,0.955726 6.54387,2.268743 1.93797,1.340014 3.27803,3.431202 4.52429,5.430748 1.15937,1.86013 2.02823,3.911659 2.68197,6.00375 0.44313,1.418095 -0.0611,2.785406 -1.11009,3.048506 -1.5526,0.389427 -2.73729,-0.523968 -3.06642,-1.954231 -1.01955,-4.430465 -4.81952,-11.138559 -9.60898,-10.746992 -5.21531,0.426383 -6.58135,5.100204 -7.14687,7.900847 -0.33773,1.672483 -0.48285,3.226132 -2.36399,3.287795 -1.88114,0.06166 -2.33499,-1.439118 -2.17645,-2.66582 0.3916,-3.02998 1.01617,-5.599472 2.57913,-7.878154 1.03137,-1.50366 2.60779,-2.641026 4.23699,-3.459828 z",
  ];

export const Paths = ({ lastTwo }: { lastTwo: Mood[] }) => {
  const { M0, M2, M3, M4 } = Mood;

  return (
    <>
      <path className={classNames("leftEye", lastTwo[1] === M3 && "happy")}>
        <Animate
          from={leftEye[Object.values(Mood).indexOf(lastTwo[0])]}
          to={leftEye[Object.values(Mood).indexOf(lastTwo[1])]}
        />
      </path>
      <path
        className={classNames(
          "rightEye",
          (lastTwo[1] === M3 && "happy") || (lastTwo[1] === M4 && "duchenne")
        )}
      >
        <Animate
          from={rightEye[Object.values(Mood).indexOf(lastTwo[0])]}
          to={rightEye[Object.values(Mood).indexOf(lastTwo[1])]}
        />
      </path>
      <path
        className={classNames("nose", lastTwo[1] === M2 && "relaxed")}
        d="m 105,94 c 5,-2 13,1 11,11 -2,10 -13,13 -18,11"
      />
      <path
        className={classNames("sweat", lastTwo[1] === M0 && "drip")}
        d="m 31,82 c 2.603417,0 5,-2 5,-6 0,-4 -5,-12 -5,-12 0,0 -5,8 -5,12 0,4 2.396583,6 5,6 z"
      />
      <path className="mouth">
        <Animate
          from={mouth[Object.values(Mood).indexOf(lastTwo[0])]}
          to={mouth[Object.values(Mood).indexOf(lastTwo[1])]}
        />
      </path>
    </>
  );
};
