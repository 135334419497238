import React, { useEffect } from "react";

import { setAutofillTexts } from "../../contentSlice";
import { useDispatch, useSelector } from "../../../hooks";

export interface Props {
  value: string;
  children: React.ReactNode;
}

export const Autofill = ({ value }: Props) => {
  const autofillTexts = useSelector(
      (state) => state.content.meta.autofillTexts
    ),
    raw = useSelector((state) => state.content.meta.raw),
    dispatch = useDispatch();

  useEffect(() => {
    let isCancelled = false;

    if (autofillTexts == null || autofillTexts[value] == null)
      (async () => {
        if (!isCancelled && raw?.[value])
          dispatch(setAutofillTexts({ [value]: raw[value] }));
      })();

    return () => {
      isCancelled = true;
    };
  }, [value, dispatch, autofillTexts, raw]);

  return <>{(autofillTexts && autofillTexts[value]) || null}</>;
};
