import React from "react";
import { Fragment, useEffect } from "react";

import "./main.less";
import { areChapters } from "../../../../../type-guards";
import { getFormattedDate as format } from "../../../../../utilities";
import { getProgress } from "../../../../progressSlice";
import { GIFT_CARD_MODULES } from "../../../../../constants";
import { SanityContent } from "../../../../../sanity-client";
import { Section } from "../../../../../types";
import { useSelector, useDispatch } from "../../../../../hooks";

const giftcardIndices = Object.keys(GIFT_CARD_MODULES),
  none = [6, 7, 8, 9, 10, 11, 12, 13].map((key) => (
    <li {...{ key, className: "compact" }}>Uke {key} – Ingen oppgaver</li>
  ));

export const Overview = () => {
  const content = useSelector((state) => state.content.sanity),
    version = useSelector((state) => state.user.version),
    isLoggedIn = useSelector((state) => state.auth).user.id !== null,
    started = (useSelector((state) => state.progress.started) || 0) * 1000,
    dispatch = useDispatch(),
    list: SanityContent[] = Object.values(content[Section.CHAPTERS]),
    filteredList = (
      (areChapters(list) &&
        list.filter(
          ({ version1 }) =>
            (version === 1 && version1) || (version === 2 && !version1)
        )) ||
      []
    ).sort((a, b) => a.order - b.order || 0);

  useEffect(() => {
    started === 0 && isLoggedIn && dispatch(getProgress());
  }, [dispatch, started, isLoggedIn]);

  return (
    <ol className="overview">
      <li>
        <strong>
          <em style={{ fontWeight: "normal" }}>Du startet {format(started)}</em>
          <br />
          Uke 1 – Registrering, spørreskjema og introduksjon
        </strong>
        Du tjener 200 poeng
      </li>
      {filteredList.slice(1).map(({ title }, index) => (
        <Fragment key={index}>
          <li>
            <strong>
              Uke {index === 4 ? 14 : index + 2} – Modul {index + 1}: {title}
            </strong>
            {index < 4 && "Du tjener 100 poeng"}
            {index < 4 && <br />}
            {index === 4 && "Du får tilgang til et spørreskjema"}
            {index === 4 && <br />}
            {[
              "Har du fullført introduksjon og modul 1 kan du hente ut et gavekort på ",
              "Har du fullført modul 2-4 kan du hente ut gavekort på ",
              "Gavekort på ",
            ].find((_, i) => i === giftcardIndices.indexOf(index + 1 + ""))}
            {giftcardIndices.map((key) =>
              parseInt(key) === index + 1 ? GIFT_CARD_MODULES[key] + "" : ""
            )}
            {[" kroner", " kroner", " kroner i fullføringsbonus!"].find(
              (_, i) => i === giftcardIndices.indexOf(index + 1 + "")
            )}
          </li>
          {index === 3 && none}
        </Fragment>
      ))}
    </ol>
  );
};
