import React from "react";
import { Overview } from "../../page/body/overview/Overview";

export const ContentOverview = (args: { backButton: () => void }) => (
  <section className="settingsPage">
    <h1>Slik fungerer RØST</h1>
    {args.backButton()}
    <p>
      Ved å delta i RØST vil du tjene poeng som kan veksles inn i gavekort til
      en samlet verdi på 1000 kroner.{" "}
      <a href="/giftcard">Oversikt over dine gavekort finner du her</a>.
    </p>
    <p>
      Hver uke får du tilgang til en ny modul som må fullføres før uken er omme.
      Dersom du ikke skulle rekke å ta ukens modul, kan du likevel se innholdet
      i modulen, men du kan ikke tjene opp poeng. Under er en oversikt over din
      deltakelse:
    </p>
    <Overview />
  </section>
);
