import React from "react";
import classNames from "classnames";

import "./main.less";
import { Props as HeaderProps } from "../Header";
import { ReactComponent as KvinneOgMann } from "../../../common/graphics/kvinneogmann.svg";
import { ReactComponent as Skrivebord } from "../../../common/graphics/skrivebord.svg";

type Props = {
  ingress: string;
  secondaryIngress?: string;
} & Pick<HeaderProps, "theme">;

export const Ingress = ({ ingress, secondaryIngress, theme }: Props) => {
  let split = theme === "light" ? 60 : 40;

  if (ingress.length > 0) while (ingress[split] !== " " && split > 0) split--;

  return (
    <div className={classNames("ingress", ingress.length > 0 && "visible")}>
      <span>
        {ingress.slice(0, ingress.length - split)}
        {theme === "light" ? <Skrivebord /> : <KvinneOgMann />}
        {ingress.slice(ingress.length - split, ingress.length)}
        {secondaryIngress && <small>{secondaryIngress}</small>}
      </span>
    </div>
  );
};
