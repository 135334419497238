import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";
import { AboutSection } from "./AboutSection";

export const About = ({ content, children }: WizardContentProps) => (
  <>
    <Header
      title={(isGeneral(content) && content.consentTitle) || undefined}
      description={
        (isGeneral(content) &&
          content.consentDescription.replace(/\n- /g, "\n• ")) ||
        undefined
      }
    />
    <AboutSection>{children()}</AboutSection>
  </>
);
