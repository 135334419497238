import React from "react";
import { Input } from "nav-frontend-skjema";
import { userService } from "../../api";
import { useState } from "react";

import "./main.less";
import { Button } from "../common/button/Button";
import { ContentProps as WizardContentProps } from "../common/wizard/Wizard";
import { doUserGetMe } from "../userSlice";
import { useDispatch } from "../../hooks";

export const Mobile = (args: WizardContentProps) => {
  const [mobileNumber, setMobileNumber] = useState<string | null>(null),
    dispatch = useDispatch(),
    { next } = args;

  return (
    <section className="objective">
      <h1>SMS-varsling</h1>
      <p>
        Vi sender deg en påminnelse med avsender UiS når en ny modul i RØST er
        tilgjengelig. Telefonnummeret ditt lagres adskilt fra øvrige data.
      </p>
      <Input
        label="Mobilnummer"
        value={mobileNumber || ""}
        onBlur={(event) => setMobileNumber(event.target.value)}
        onChange={(event) => setMobileNumber(event.target.value)}
        {...(mobileNumber !== null && isNaN(parseInt(mobileNumber || ""))
          ? { feil: "Mobilnummer mangler" }
          : mobileNumber?.length !== undefined && mobileNumber?.length < 8
          ? { feil: "Mobilnummer må være åtte siffer" }
          : {})}
      />
      <p>
        <br />
      </p>
      <Button
        disabled={
          mobileNumber === null ||
          isNaN(parseInt(mobileNumber || "")) ||
          (mobileNumber?.length !== undefined && mobileNumber?.length < 8)
        }
        onClick={async () => {
          if (mobileNumber !== null) {
            await userService.setUserMobile(mobileNumber); // TODO: Error handling
            dispatch(doUserGetMe());
            next();
          }
        }}
        type="hoved"
      >
        Gå videre
      </Button>
    </section>
  );
};
