import { useEffect, useState } from "react";

import { getGiftcards } from "../giftcardThunk";
import { Timeout } from "../../types";
import { useDispatch, useSelector } from "../../hooks";

export const useTimeout = () => {
  const [count, setCount] = useState(0),
    dispatch = useDispatch(),
    giftcards = useSelector((state) => state.progress.giftcards);

  useEffect(() => {
    let timeout: Timeout;

    if (giftcards?.some((giftcard) => giftcard === null)) {
      localStorage.setItem("pendingGiftcards", "true");
      dispatch(getGiftcards());
    }
    if (localStorage.getItem("pendingGiftcardsTimestamp") !== null) {
      const diff =
        new Date().valueOf() -
        parseInt(localStorage.getItem("pendingGiftcardsTimestamp") as string);

      if (diff < 120000)
        for (let i = parseInt((diff / 1200).toFixed(0)); i < 100; i++) {
          timeout = setTimeout(
            () => setCount(i),
            (i - parseInt((diff / 1200).toFixed(0))) * 1200
          );
        }
    }

    return () => clearTimeout(timeout);
  }, [dispatch, giftcards]);

  return count;
};
