import React from "react";
import { LinkButton } from "../common/button/Button";
import { Redirect } from "react-router-dom";

import { format } from "../../formatter";
import { getImageURL } from "../../utilities";
import { SanityGeneral } from "../../sanity-client";

export const renderSlides = ({
  content,
  SLIDE_DURATION_IN_MS,
}: {
  content: SanityGeneral;
  SLIDE_DURATION_IN_MS: number;
}) => [
  <>
    <div>{format(content.splash.split("\n")[0], false)}</div>
    <img
      src={getImageURL(content.splashImage.asset._ref)}
      alt={content.splashImage.caption}
    />
    <div>{format(content.splash.split("\n")[1], false)}</div>
    <span className="nudge">
      <LinkButton to="/home">Neste</LinkButton>
    </span>
    <small>
      Matmik, CC BY-SA 3.0{" "}
      <a href="https://commons.wikimedia.org/wiki/File:V%C3%A6r%C3%B8y_og_R%C3%B8st.jpg">
        Matmik
      </a>
      ,{" "}
      <a href="https://creativecommons.org/licenses/by-sa/3.0">CC BY-SA 3.0</a>,
      via Wikimedia Commons
    </small>
    {SLIDE_DURATION_IN_MS > 0 && (
      <span
        key="1"
        className="loader"
        style={{ animationDuration: SLIDE_DURATION_IN_MS + "ms" }}
      />
    )}
  </>,
  <Redirect to="/home" />,
];
