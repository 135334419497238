import React from "react";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { AboutSection } from "../onboarding/AboutSection";
import { AccessSection } from "../onboarding/AccessSection";
import { PrivacySection } from "../onboarding/PrivacySection";
import { TermsSection } from "../onboarding/TermsSection";

export const RegistrationInfo = ({ children }: WizardContentProps) => (
  <section className="settingsPage">
    <AboutSection withPadding={false} />
    <AccessSection withPadding={false} includeNextPageShort={false} />
    <PrivacySection withPadding={false} />
    <TermsSection withPadding={false} />
    {children()}
  </section>
);
