import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";
import { PrivacySection } from "./PrivacySection";

export const Privacy = ({ content, children }: WizardContentProps) => (
  <>
    <Header
      title={(isGeneral(content) && content.consentTitle) || undefined}
      collapsed={true}
    />
    <PrivacySection>{children()}</PrivacySection>
  </>
);
