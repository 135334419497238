import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { contentService, userService } from "../api";
import { getMassage, getPageCounts as count } from "../utilities";
import { Params, SanityContent } from "../sanity-client";
import { Section, Meta, Items } from "../types";

type IdPayloadAction = PayloadAction<{ id: string; parentId?: string }>;
type AutofillPayloadAction = PayloadAction<Record<string, string>>;

export const getContent = createAsyncThunk(
    "content/getContent",
    async (params: Params) => await contentService.get(params)
  ),
  getAnswers = createAsyncThunk(
    "content/getAnswers",
    async (keys: string[]) => {
      const lock: string[] = [],
        data = await userService.getUserFeedbackData(),
        answers = keys.map((key) => {
          let result = (data !== null && data[key]) || " ",
            noLock =
              key.indexOf("schedule__") === 0 || key.indexOf("sound__") === 0;

          result !== " " && !noLock && lock.push(key);

          return result;
        });

      return { raw: data, answers, lockedTasks: lock };
    }
  ),
  getAnswer = createAsyncThunk("content/getAnswer", async (key: string) => {
    const data = await userService.getUserFeedbackData(),
      answer = data !== null && data[key];

    return answer;
  }),
  setFadeOut = createAsyncThunk(
    "content/setFadeOut",
    async () => new Promise<void>((resolve) => setTimeout(() => resolve(), 250))
  ),
  {
    actions: { setIds, setAnswers, setAutofillTexts, setMovedBack },
    reducer: contentSliceReducer,
  } = createSlice({
    name: "content",
    initialState: { sanity: {}, meta: {} } as {
      sanity: Record<Section, Items<SanityContent>>;
      meta: Meta;
    },
    reducers: {
      setIds: (state, { payload: { id, parentId } }: IdPayloadAction) => {
        state.meta.currentId = id;
        state.meta.currentParentId = parentId;
      },
      setAnswers: (state, { payload }: PayloadAction<string[]>) => {
        state.meta.answers = payload;
      },
      setMovedBack: (state, { payload }: PayloadAction<boolean>) => {
        state.meta.movedBack = payload;
      },
      setAutofillTexts: (state, { payload }: AutofillPayloadAction) => {
        state.meta.autofillTexts = { ...state.meta.autofillTexts, ...payload };
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getContent.fulfilled, (state, { payload }) => {
        payload.length > 0 &&
          (state.sanity[payload[0]._type] = getMassage(payload));
        if (state.sanity.page) state.meta.pageCounts = count(state.sanity);
      });
      builder.addCase(getAnswers.fulfilled, ({ meta }, { payload }) => {
        meta.raw = payload.raw;
        meta.answers = payload.answers;
        meta.lockedTasks = payload.lockedTasks;
        meta.autofillTexts = null;
      });
      builder.addCase(getAnswer.fulfilled, ({ meta }, { payload }) => {
        meta.answer = payload;
      });
      builder.addCase(setFadeOut.pending, (state) => {
        state.meta.fading = true;
      });
      builder.addCase(setFadeOut.fulfilled, (state) => {
        state.meta.fading = false;
      });
    },
  });
