import React from "react";
import { useEffect, useRef, useState } from "react";

import "./main.less";
import { Button } from "../../common/button/Button";
import { ContentProps as WizardContentProps } from "../../common/wizard/Wizard";
import { Header } from "../../common/header/Header";
import { Mood as Moods } from "./moods";
import { Paths } from "./Paths";
import { reachPage } from "../../progressSlice";
import { reportMood } from "../../moodThunk";
import { useDispatch, useSelector } from "../../../hooks";

export const Mood = ({ next, exit }: WizardContentProps) => {
  const svg = useRef<SVGSVGElement>(null),
    dispatch = useDispatch(),
    { lastReportedMood, chapter } = useSelector((state) => state.progress),
    [lastTwo, setLastTwo] = useState<Moods[]>([Moods.M2, Moods.M2]);

  useEffect(() => {
    const index = lastReportedMood === 0 ? 0 : lastReportedMood;

    index !== undefined && setLastTwo([Moods.M2, Object.values(Moods)[index]]);
  }, [lastReportedMood]);

  useEffect(() => {
    svg.current
      ?.querySelectorAll("animate")
      .forEach((el: SVGAnimateElement) => el.beginElement());
  }, [lastTwo]);

  return (
    <>
      <Header homeButton={true} ancillaryButtons={true} collapsed={true} />
      <section className="mood">
        <h2>Hei! Hvordan er humøret ditt i dag?</h2>
        <div className="head">
          <svg ref={svg} width="200" height="200" viewBox="0 0 200 200">
            <Paths lastTwo={lastTwo} />
          </svg>
        </div>
        <p>{lastTwo[1]}</p>
        <div className="inputWrapper">
          <input
            type="range"
            min="0"
            max="4"
            defaultValue={lastReportedMood === 0 ? 0 : lastReportedMood || 2}
            onChange={({ target: { value } }) =>
              setLastTwo([
                lastTwo[1],
                Object.entries(Moods)[parseInt(value)][1],
              ])
            }
          />
        </div>
        <Button
          type="hoved"
          onClick={() => {
            dispatch(
              reportMood({
                mood: Object.values(Moods).indexOf(lastTwo[1]),
              })
            );
            dispatch(reachPage({ chapter, pageNumber: 0 }));
            next();
          }}
        >
          Neste
        </Button>
        <Button type="flat" onClick={exit}>
          Hopp over
        </Button>
      </section>
    </>
  );
};
