import React from "react";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./main.less";
import { isGeneral } from "../../type-guards";
import { renderSlides } from "./renderSlides";
import { SanityContent } from "../../sanity-client";
import { Section, Timeout } from "../../types";
import { useSelector } from "../../hooks";

const SLIDE_DURATION_IN_MS = -1;
// NUDGE_DELAY_IN_MS = 0;

export const Splash = () => {
  const globalContent = useSelector((state) => state.content.sanity),
    [nudge] = useState(true),
    [slide, setSlide] = useState(0),
    wrapper = useRef<HTMLDivElement>(null),
    content: SanityContent =
      globalContent[Section.GENERAL] &&
      globalContent[Section.GENERAL][Section.GENERAL];
  let slides: JSX.Element[];

  useEffect(() => {
    if (SLIDE_DURATION_IN_MS > 0) {
      let timeout: Timeout = setTimeout(() => {
        setSlide(slide + 1);
      }, SLIDE_DURATION_IN_MS);

      return () => clearTimeout(timeout);
    }
  }, [slide]);

  // useLayoutEffect(() => {
  //   let timeout: Timeout = setTimeout(() => {
  //     wrapper.current &&
  //       wrapper.current.scrollTop === 0 &&
  //       wrapper.current.scrollHeight > wrapper.current.clientHeight &&
  //       setNudge(true);
  //   }, NUDGE_DELAY_IN_MS);

  //   return () => clearTimeout(timeout);
  // }, []);

  if (!isGeneral(content)) return null;
  slides = renderSlides({ content, SLIDE_DURATION_IN_MS });

  return (
    <div
      ref={wrapper}
      className={classNames("splash", !nudge && "hideNudge")}
      onClick={() =>
        wrapper.current &&
        wrapper.current.scrollTo(0, wrapper.current.scrollTop + 999)
      }
      // onScroll={() => setNudge(false)}
    >
      <h1>{content.title}</h1>
      {slides[slide]}
      {/* <LinkButton to="/home">Neste</LinkButton> */}
    </div>
  );
};
