import React from "react";
import { Progress } from "../common/progress/Progress";
import NavFrontendSpinner from "nav-frontend-spinner";

export const GiftCardStatus = ({
  count,
  children = "Laster...",
}: {
  count?: number;
  children?: React.ReactNode;
}) => (
  <>
    <p style={{ textAlign: "center" }}>
      {count === undefined && <NavFrontendSpinner />}
      {count === undefined && <br />}
      <strong>{children}</strong>
    </p>
    {count !== undefined && (
      <>
        <p style={{ textAlign: "center" }}>
          Vennligst vent, eller kom tilbake hit om noen strakser...
        </p>
        <Progress value={count} complete={false} className="alt" />
        <br />
      </>
    )}
  </>
);
