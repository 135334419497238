import * as Autofill from "./features/common/autofill/Autofill";
import * as Quote from "./features/common/quote/Quote";

export type Rule = [
  string,
  (
    | string
    | ((args: Partial<Quote.Props> & Partial<Autofill.Props>) => JSX.Element)
  )
];

export const rules: Rule[] = [
    ["\n", "p"],
    ["**", "strong"],
    ["##", "i"],
    ["^^", "mark"],
    ["==", "a"],
    ["@@", "img"],
    ["%%", Autofill.Autofill as (args: Partial<Autofill.Props>) => JSX.Element],
    ['""', Quote.Quote as (args: Partial<Quote.Props>) => JSX.Element],
  ],
  prepare = (input: string) => {
    let output = input
      .replace(/\n\* /g, "\n• ")
      .replace(/\u2028/g, "")
      .replace(/(^@)(.*?)(?=$)/g, "@@$2@@")
      .replace(/(^@)(.*?)(?=\n)/g, "@@$2@@")
      .replace(/(\n@)(.*?)(?=$)/g, "@@$2@@")
      .replace(/(\n@)(.*?)(?=\n)/g, "@@$2@@");

    return `\n${output.replace(/\n\n/g, "\n").replace(/\n/g, "\n\n")}\n`;
  };
