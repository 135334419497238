import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import "./main.less";
import { Button } from "../button/Button";
import { SanityContent } from "../../../sanity-client";
import { Section } from "../../../types";
import { setIds } from "../../contentSlice";
import { useDispatch } from "../../../hooks";

export interface ContentProps {
  next: (toggle?: boolean) => void;
  previous: () => void;
  exit: () => void;
  children: (noForward?: boolean) => React.ReactNode;
  toggle?: boolean;
  content?: SanityContent;
}

export interface Props {
  steps: ((args: ContentProps) => JSX.Element)[];
  exitConfig: { id?: string; parentId?: string; path: Section | "survey1" };
  content?: SanityContent;
}

export const Wizard = (args: Props) => {
  const {
      steps,
      exitConfig: { parentId, path, id = path },
      content,
    } = args,
    history = useHistory(),
    dispatch = useDispatch(),
    [step, setStep] = useState(0),
    [toggle, setToggle] = useState<boolean | undefined>(undefined),
    exit = () => {
      dispatch(setIds({ id, parentId }));
      history.push(`/${path}`);
    },
    next = (toggle?: boolean) => {
      setToggle(toggle);
      step + 1 <= steps.length - 1 ? setStep(step + 1) : exit();
    },
    previous = () => step - 1 > -1 && setStep(step - 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return React.createElement(steps[step], {
    next,
    previous,
    exit,
    children: (noForward?: boolean) => (
      <nav className="basicWizardNavigation">
        <Button disabled={step - 1 < 0} type="flat" onClick={previous}>
          Forrige
        </Button>
        <Button
          type="hoved"
          onClick={() =>
            noForward
              ? alert("Vennligst fyll ut alle påkrevde felter for å gå videre.")
              : next()
          }
        >
          Neste
        </Button>
      </nav>
    ),
    toggle,
    content,
  });
};
