import React from "react";
import { Select } from "nav-frontend-skjema";

import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";

export const When = (args: {
  locked: boolean;
  answers: string[];
  index: number;
}) => {
  const { locked, answers, index } = args,
    dispatch = useDispatch();

  return (
    <Select
      disabled={locked}
      className="timePicker"
      onChange={(event) => {
        const copy = answers.slice();

        copy[index] = event.target.value;
        dispatch(setAnswers(copy));
      }}
      value={answers[index]}
    >
      <option value="">Velg tidspunkt</option>
      <option value="morning">Morgen</option>
      <option value="evening">Kveld</option>
    </Select>
  );
};
