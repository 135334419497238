import React from "react";
import { Knapp, KnappBaseProps } from "nav-frontend-knapper";
import { Link, LinkProps } from "react-router-dom";
import classNames from "classnames";

import "./main.less";

export const Button = ({
  compact,
  ...rest
}: Omit<KnappBaseProps, "kompakt"> & { compact?: boolean }) => (
  <Knapp kompakt={compact} {...rest} />
);

export const LinkButton = ({
  compact,
  type,
  ...rest
}: LinkProps & Omit<KnappBaseProps, "type"> & { compact?: boolean }) => (
  <Link
    className={classNames(
      "knapp",
      compact && "knapp--kompakt",
      `knapp--${type}`
    )}
    {...rest}
  />
);
