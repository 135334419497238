import React from "react";
import "./main.less";
import { Button } from "../common/button/Button";
import { getFormattedDateFull } from "../../utilities";
import { GiftCardData } from "../../types";
import { ReactComponent as PiggyBank } from "../common/graphics/piggybank.svg";

export const GiftCardItem = ({ value, pin, expiry, url }: GiftCardData) => (
  <li key={pin} className="giftCardCard">
    <h2 data-value={value}>
      <PiggyBank />
      <br />
      Gratulerer med gavekort!
    </h2>
    <p>
      <strong>Gavekort:</strong> {value} kroner
    </p>
    <p>
      <strong>Pinkode:</strong> {pin}
    </p>
    <p>
      <strong>Gavekortet utløper:</strong> {getFormattedDateFull(expiry)}
    </p>
    <Button type="hoved" onClick={() => window.open(url, "_blank")}>
      Last ned gavekort
    </Button>
    <p>Slik bruker du ditt gavekort:</p>
    <ol>
      <li>Tast inn verdikode og pin på gogift.com</li>
      <li>Velg produkt eller tjeneste</li>
      <li>GoGift sender deg din gave!</li>
    </ol>
  </li>
);
