import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { authService } from "../api";
import { doUserGetMe } from "./userSlice";
import { isApiError } from "../type-guards";
import { setBearer } from "../axios";

interface State {
  paused: boolean;
  oneTimeCode: string | null;
  jwt: string | null;
  user: { id: number | null };
}

type Payload = { data?: { access_token: string }; access_token: string };

const setDataOnVerifyCodeFulfilled = (state: State, payload: Payload) => {
    state.jwt = payload.data?.access_token || payload.access_token;
    if (state.jwt !== null) {
      setBearer(state.jwt);
      state.user.id = JSON.parse(
        decodeURIComponent(
          atob(state.jwt.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        )
      ).sub;
    }
  },
  state = { paused: false, oneTimeCode: null, jwt: null, user: { id: null } },
  delay = 2 * 60 * 1000;
let interval: number | null = null;

export const doVerifyCode = createAsyncThunk<Payload, Record<string, string>>(
    "auth/verifyCode",
    async (code, { rejectWithValue }) => {
      try {
        return await authService.verifyCode(code);
      } catch (err) {
        return rejectWithValue(isApiError(err) && err.response.data);
      }
    }
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doJwtRefresh = () => async (dispatch: any) => {
    try {
      dispatch(verifyCodeFulfilled(await authService.refresh()));
      dispatch(doUserGetMe());
    } catch (err) {}
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keepRefreshingJwt = () => (dispatch: any) => {
    if (window.location.href.indexOf("oauth_callback") === -1)
      dispatch(doJwtRefresh());
    if (interval) clearInterval(interval);
    interval = window.setInterval(async () => dispatch(doJwtRefresh()), delay);
  },
  {
    actions: { setPaused, setOneTimeCode, verifyCodeFulfilled },
    reducer: authSliceReducer,
  } = createSlice({
    ...{ name: "auth", initialState: state as State },
    reducers: {
      setPaused(state, { payload }) {
        state.paused = payload;
      },
      setOneTimeCode(state, { payload }) {
        state.oneTimeCode = payload;
      },
      verifyCodeFulfilled(state, { payload }) {
        !state.paused && setDataOnVerifyCodeFulfilled(state, payload);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(doVerifyCode.fulfilled, (state, { payload }) =>
        setDataOnVerifyCodeFulfilled(state, payload)
      );
    },
  });
