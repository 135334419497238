import { client } from "./sanity-client";
import axios, { url2data } from "./axios";

import { GiftCardData } from "./types";

const progressGet = (): Promise<{
    chapter: number;
    pageNumber: number | null;
    completed: number[];
    dropped: number[];
    started: number;
    moods: number[];
  }> => axios.get(`core/progress`).then((result) => result.data.data),
  progressPut = async (
    chapter: number,
    description: string,
    pageNumber?: number,
    mood?: number
  ) =>
    axios.put(`core/progress`, {
      chapter,
      description,
      pageNumber: pageNumber !== undefined ? pageNumber : null,
      mood,
    }),
  progressGetGiftcards = (): Promise<{ giftcards: GiftCardData[] }> =>
    axios.get(`core/progress/giftcards`).then((result) => result.data.data);

export const progressService = {
    get: progressGet,
    put: progressPut,
    getGiftcards: progressGetGiftcards,
  },
  contentService = {
    get: client,
  },
  userService = {
    getMe: () => url2data("core/user"),
    setUserObjective: (objective: "jobb" | "utdanning") =>
      axios.post("core/user", {
        choiceOfDirection: objective,
      }),
    setUserMobile: (mobile: string) => axios.post("core/user", { mobile }),
    getUserFeedbackData: () =>
      url2data("core/data").then((result) =>
        result.data === null
          ? null
          : result.data.data === null
          ? null
          : JSON.parse(result.data.data)
      ),
    addUserFeedbackData: async (entry: Record<string, string>) => {
      let data = await url2data("core/data").then((result) =>
        result.data === null
          ? null
          : result.data.data === null
          ? null
          : JSON.parse(result.data.data)
      );

      return axios.post("core/data", {
        data: JSON.stringify({ ...data, ...entry }),
      });
    },
  },
  authService = {
    logout: () =>
      axios
        .post("core/auth/logout")
        .finally(() => (window.location.href = "/")),
    getLoginUrl: (code?: string) =>
      url2data(`core/oidc/url/${code || "apekatt"}`).then(
        (result) => result.data
      ),
    verifyCode: (code: Record<string, string>) =>
      url2data("core/oidc/code", code).then((result) => result.data),
    refresh: () => url2data("core/auth/refresh", {}),
    count: () => axios.get("core/oidc/count"),
  };
