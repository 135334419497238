import React from "react";

export const Sparkles = ({ children }: { children: React.ReactNode }) => (
  <svg
    width="240"
    height="200"
    viewBox="0 0 240 200"
    xmlns="http://www.w3.org/2000/svg"
  >
    {Array.from({ length: 30 }).map((_, index) => (
      <svg
        key={index}
        x={
          0.8 *
          (1 - parseFloat("0." + "310336050771222710436381741820"[index])) *
          212
        }
        y={
          0.8 *
          (1 - parseFloat("0." + "726565370073440511230772549275"[index])) *
          172
        }
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform-origin="14px 14px"
          transform={`scale(${
            (1 - parseFloat("0." + "48064843745186200241363032195116"[index])) *
              0.75 +
            0.25
          })`}
          d="M4.62386 14.0347C4.62386 14.0347 9.88112 13.9518 12.0104 11.8225C14.1396 9.69329 14.2225 0.296542 14.2225 0.296542C14.2225 0.296542 14.3054 9.69329 16.4346 11.8225C18.5639 13.9518 23.8211 14.0347 23.8211 14.0347C23.8211 14.0347 18.5639 14.1176 16.4346 16.2468C14.3054 18.376 14.2225 27.7728 14.2225 27.7728C14.2225 27.7728 14.1396 18.376 12.0104 16.2468C9.88112 14.1176 4.62386 14.0347 4.62386 14.0347Z"
          fill="white"
          opacity={
            (1 -
              parseFloat("0." + "77835198255342565231104141006612"[index]) /
                2) *
            0.5
          }
        />
      </svg>
    ))}
    {children}
  </svg>
);
