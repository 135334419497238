import React from "react";

import "./main.less";

export const Summary = () => (
  <div className="summary">
    {[
      [
        "Spørreundersøkelser",
        "I RØST vil vi be deg svare på tre spørreundersøkelser. Den første får du i dag og den siste får du om tretten uker. Hver spørreundersøkelse tar 10-15 minutter. Spørsmålene handler om hvilke tanker du har rundt læring, motivasjon og om hvordan du har det. Vi vil også spørre deg om hva du tenker om Oppfølgingstjenesten.",
      ],
      [
        "Moduler: 15-30 min hver uke",
        "RØST har fire moduler over fire uker. Hver modul tar ca. 15-30 minutter å gjennomføre. Du vil få SMS-påminnelser når en ny modul er klar.",
      ],
      [
        "Belønning",
        "Dersom du fullfører spørreskjema og moduler vil du tjene poeng. Disse poengene kan veksles inn i tre gavekort med en samlet verdi på 1000 kroner. Det første får du etter modul 1, det andre etter modul 4 og det siste etter det avsluttende spørreskjemaet. Detaljer om opptjening av poeng og gavekort finner du i innstillingene til RØST appen.",
      ],
    ].map(([title, text], index) => (
      <React.Fragment key={index}>
        <p className="title">
          <strong>{title}</strong>
        </p>
        <span className="arrow"></span>
        <p className={(index === 3 && "last") || ""}>{text}</p>
      </React.Fragment>
    ))}
  </div>
);
