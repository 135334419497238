import React from "react";
import { Button } from "../../../../../common/button/Button";
import { ReactComponent as SoundOff } from "../../../../../common/graphics/soundoff.svg";
import { ReactComponent as SoundOn } from "../../../../../common/graphics/soundon.svg";
import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";

export const Sound = (args: {
  answers: string[];
  index: number;
  placeholder: string;
}) => {
  const { placeholder, answers, index } = args;
  const dispatch = useDispatch();

  const isSoundOn = getSoundState();

  function getSoundState() {
    return JSON.parse(localStorage.getItem("autoplay_sound") || "false");
  }

  // Save sound state to local storage
  function saveSoundState(isOn: boolean) {
    localStorage.setItem("autoplay_sound", JSON.stringify(isOn));
  }

  return (
    <Button
      style={{ width: "auto", margin: "2rem auto", display: "flex" }}
      className="sound"
      type="hoved"
      name="work"
      onClick={() => {
        const copy = answers.slice();
        copy[index] = isSoundOn ? "false" : "true";
        dispatch(setAnswers(copy));

        saveSoundState(!isSoundOn);
      }}
    >
      {isSoundOn ? <SoundOff /> : <SoundOn />}&nbsp;&nbsp;
      {placeholder.replace("på", isSoundOn ? "av" : "på")}
    </Button>
  );
};
