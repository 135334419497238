import React from "react";
import classNames from "classnames";

import "./main.less";

interface Props {
  Icon: () => JSX.Element;
  fulfilled: boolean;
  children: React.ReactNode;
  compact: boolean;
}

export const Status = ({ Icon, fulfilled, children, compact }: Props) => (
  <p
    className={classNames(
      "status",
      fulfilled && "fulfilled",
      compact && "compact"
    )}
  >
    <Icon />
    {children}
  </p>
);
