import React, { useCallback, useMemo } from "react";
import { useEffect, useRef, useState } from "react";

import { CDN_URL } from "../../../../constants";
import { SanityVideo } from "../../../../sanity-client";
import { setPaused as setPausedAuth } from "../../../authSlice";
import { useDispatch } from "../../../../hooks";
import config from "../../../../sanity-config.json";

export const useVideo = (video: SanityVideo | undefined) => {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [play, setPlay] = useState(false);
  const [finished, setFinished] = useState(false);

  const src = useMemo(() => {
    return video
      ? `${CDN_URL}files/${config.projectId}/${
          config.dataset
        }/${video.asset._ref.replace(/file-|-mp4/g, "")}.${"mp4"}`
      : "";
  }, [video]);

  const VideoElement = useCallback(
    () => (
      <video ref={videoRef} controls>
        <source src={src} type="video/mp4" />
      </video>
    ),
    [src]
  );

  function onVideoLoad() {
    dispatch(setPausedAuth(!play));
    setPlay(true);
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleEnded = () => setFinished(true);

    if (videoElement) {
      videoElement.addEventListener("ended", handleEnded);
      videoElement.addEventListener("loadeddata", onVideoLoad);

      return () => {
        videoElement.removeEventListener("ended", handleEnded);
        videoElement.removeEventListener("loadeddata", onVideoLoad);
        setPlay(false);
        dispatch(setPausedAuth(false));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { VideoElement, videoPlay: play, videoDone: finished };
};
