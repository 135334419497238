import React from "react";

import { getPageContentById } from "../../../utilities";
import { Header } from "../../common/header/Header";
import { isPage, isMilestone } from "../../../type-guards";
import { LinkButton } from "../../common/button/Button";
import { Milestone, Props as MilestoneProps } from "../../milestone/Milestone";
import { Page } from "./Page";
import { useMemoCompare, useSelector } from "../../../hooks";
import * as Sanity from "../../../sanity-client";

export const PageContainer = (args: {
  id: string;
  parent: Sanity.SanityChapter;
  list: Sanity.SanityPage[];
}) => {
  const { id, parent, list } = args,
    choiceOfDirection = useSelector((state) => state.user.choiceOfDirection),
    pageNumber = useSelector((state) => state.progress.pageNumber),
    raw = useSelector((state) => state.content.meta.raw),
    path = choiceOfDirection === "jobb" ? "work" : "education",
    group = list.filter(({ parent: { _ref } }) => _ref === parent._id),
    sorted = group.sort((a, b) => a.order - b.order || 0),
    map = useMemoCompare(
      {
        parentId: parent._id,
        list: list
          .filter(({ parent: { _ref } }) => _ref === parent._id)
          .sort((a, b) => a.order - b.order || 0)
          .reduce((map, { _id, order }) => ({ ...map, [_id]: order }), {}),
      },
      ({ parentId: prev }, { parentId: next }) => prev === next
    ).list,
    headerProps = { homeButton: true, ancillaryButtons: true, collapsed: true },
    cta = React.createElement(LinkButton, { to: "/" }, "Til forsiden");
  let content: Sanity.SanityPage | MilestoneProps | undefined,
    condition,
    skip = false;

  if (id === "milestone") content = { ...parent, cta };
  else if (id === "initial") content = sorted[0];
  else if (id === "persisted" && pageNumber !== undefined)
    content = sorted[pageNumber];
  else content = getPageContentById(id, group, raw, path, map);
  condition =
    isPage(content) &&
    content.category?.indexOf("Betinget") === 0 &&
    content.condition &&
    content.condition.length > 0
      ? content.condition
      : "";
  if (condition !== "") {
    const pair =
        condition.split("=").length === 2 ? condition.split("=") : false,
      match = pair && raw && raw[pair[0].replace("X", path)],
      value = pair && pair[1],
      not = value && value.indexOf("!") === 0,
      or = value && value.indexOf("?") === 0,
      nor = value && value.indexOf("!?") === 0;

    if (match && match !== undefined) {
      if (or) skip = match.indexOf(value.slice(1)) === -1;
      else if (nor) skip = match.indexOf(value.slice(2)) !== -1;
      else skip = value && not ? match === value.slice(1) : match !== value;
    }
  }

  return (
    <>
      <Header {...{ ...headerProps, milestone: !isPage(content) }} />
      {isPage(content) ? (
        <Page content={content} skip={skip} parent={parent} map={map} />
      ) : (
        (isMilestone(content) && <Milestone {...content} />) || null
      )}
    </>
  );
};
