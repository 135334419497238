import React from "react";
import { ReactComponent as BraHode } from "../../../common/graphics/brahode.svg";
import { ReactComponent as DarligHode } from "../../../common/graphics/darlighode.svg";
import { ReactComponent as EmptyMood } from "../../../common/graphics/emptymood.svg";
import { ReactComponent as FantastiskHode } from "../../../common/graphics/fantastiskhode.svg";
import { ReactComponent as ForferdeligHode } from "../../../common/graphics/forferdelighode.svg";
import { ReactComponent as OkayHode } from "../../../common/graphics/okayhode.svg";

interface Props {
  backButton: () => void;
  moods: number[];
}

const illustrations = [
  <ForferdeligHode />,
  <DarligHode />,
  <OkayHode />,
  <BraHode />,
  <FantastiskHode />,
  <EmptyMood />,
];

export const MoodOverview = ({ backButton, moods }: Props) => (
  <section className="settingsPage">
    <h1>Humøroversikt</h1>
    {backButton()}
    <p>
      Her kan du se hvordan du har hatt det i løpet av de ukene du har brukt
      appen.
    </p>
    <br />
    <div className="weeklyMood">
      {illustrations[moods[0]]}
      Uke 1
    </div>
    <div className="weeklyMood">
      {illustrations[moods[1]]}
      Uke 2
    </div>
    <div className="weeklyMood">
      {illustrations[moods[2]]}
      Uke 3
    </div>
    <div className="weeklyMood">
      {illustrations[moods[3]]}
      Uke 4
    </div>
    <p className="greenBox">
      Dersom humøret ditt holder seg i den nedre delen av skalaen, kan det være
      lurt å kontakte en fagperson som kan gi deg støtte og råd om hvordan du
      takler disse følelsene i hverdagen.
    </p>
    <p className="blueBox">
      Føler du at du har behov for hjelp? Rådet for psykisk helse har laget en
      oversikt over hvor du kan finne hjelp.
    </p>
  </section>
);
