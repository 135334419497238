import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getGiftcards, initialGiftcardState } from "./giftcardThunk";
import { Progress } from "../types";
import { progressService as api } from "../api";
import { reportMood } from "./moodThunk";

const initialState = {
  chapter: 0,
  completed: [],
  dropped: undefined,
  moods: [],
};

export const getProgress = createAsyncThunk<Progress>(
    "progress/getProgress",
    async () => {
      const data = await api.get(),
        pageNumber = data.pageNumber !== null ? data.pageNumber : undefined;

      return { ...data, pageNumber };
    }
  ),
  completeChapter = createAsyncThunk<void>(
    "progress/completeChapter",
    async (_, { getState, rejectWithValue: reject }) => {
      const progress = (getState() as { progress: Progress }).progress,
        chapter = progress.chapter === undefined ? 0 : progress.chapter;

      await api.put(chapter, "completeChapter").catch((err) => reject(err));
    }
  ),
  reachPage = createAsyncThunk<Progress, Progress>(
    "progress/reachPage",
    async ({ chapter, pageNumber }, { rejectWithValue: reject }) =>
      await api
        .put(chapter, "reachPage", pageNumber)
        .catch((err) => reject(err))
        .then(() => ({ chapter, pageNumber }))
  ),
  { reducer: progressSliceReducer } = createSlice({
    ...{ name: "progress", reducers: {} },
    initialState: { ...initialState, ...initialGiftcardState } as Progress,
    extraReducers: (builder) =>
      builder
        .addCase(getGiftcards.pending, (state) => {
          state.waitingForGiftcards = true;
        })
        .addCase(getGiftcards.fulfilled, (state, { payload }) => {
          state.giftcards = payload.giftcards;
          state.waitingForGiftcards = false;
        })
        .addCase(getProgress.fulfilled, (state, { payload }) => {
          state.chapter = payload.chapter;
          state.pageNumber = payload.pageNumber;
          state.completed = payload.completed;
          state.dropped = payload.dropped;
          state.started = payload.started;
          state.moods = payload.moods;
          state.failed = false;
        })
        .addCase(getProgress.rejected, (state) => {
          state.failed = true;
        })
        .addCase(completeChapter.fulfilled, (state) => {
          state.lastReportedMood = undefined;
          state.completed = state.completed?.concat([state.chapter]);
        })
        .addCase(reachPage.fulfilled, (state, { payload }) => {
          state.chapter = payload.chapter;
          state.pageNumber = payload.pageNumber;
        })
        .addCase(reportMood.fulfilled, (state, { payload }) => {
          state.lastReportedMood = payload;
        }),
  });
