import React from "react";
import { useState } from "react";

import "./main.less";
import { Body } from "./body/Body";
import { Dots } from "./dots/Dots";
import { getRelativeId } from "../../../utilities";
import { Navigation } from "./navigation/Navigation";
import { SanityChapter, SanityPage } from "../../../sanity-client";
import { useSelector } from "../../../hooks";
import { useTasks } from "./useTasks";

interface Props {
  content: SanityPage;
  parent: SanityChapter;
  map: Record<string, number>;
  skip: boolean;
}

export const Page = (args: Props) => {
  const {
    content,
    parent,
    parent: { order: parentOrder, _id: parentId },
    map,
    skip,
  } = args;
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const choiceOfDirection = useSelector(
    (state) => state.user.choiceOfDirection
  );
  const progress = useSelector((state) => state.progress);
  const path = choiceOfDirection === "jobb" ? "work" : "education";
  const { tasks, submitPendingAnswers } = useTasks({
    ...content,
    setDisableNext,
    path,
  });
  const disableNextOverride =
    !getRelativeId(content.order, 1, map) &&
    ((progress.completed !== undefined &&
      progress.completed.indexOf(parentOrder) !== -1) ||
      (progress.dropped !== undefined &&
        progress.dropped.indexOf(parentOrder) !== -1));

  return (
    <section className="page">
      <Dots map={map} order={content.order} />
      <Body
        {...content}
        chapter={parent}
        tasks={tasks ? tasks : []}
        path={path}
        map={map}
      />
      <Navigation
        map={map}
        skip={skip}
        order={content.order}
        chapterId={parentId}
        chapterOrder={parentOrder}
        disableNext={disableNext || disableNextOverride}
        submitPendingAnswers={submitPendingAnswers}
      />
    </section>
  );
};
