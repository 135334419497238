
import { userService } from "../../api";
import React, { useState } from "react";

import "./main.less";
import { Button } from "../common/button/Button";
import { ContentProps as WizardContentProps } from "../common/wizard/Wizard";
import { doUserGetMe } from "../userSlice";
import { Objective } from "../../types";
import { useDispatch } from "../../hooks";

export const Choice = (args: WizardContentProps) => {
  const { next } = args,
    dispatch = useDispatch(),
    [objective, setObjective] = useState<Objective>();

  return (
    <section className="objective">
      <h1>Hva ønsker du aller mest å komme i gang med?</h1>
      <p>Hvilket alternativ passer deg best?</p>
      <Button
        className="huge"
        onClick={async () => {
          setObjective("work");
          await userService.setUserObjective("jobb"); // TODO: Error handling
          dispatch(doUserGetMe());
          next(false);
        }}
        type={(objective === "work" && "hoved") || undefined}
      >
        Jobb
      </Button>
      <Button
        className="huge"
        onClick={async () => {
          setObjective("education");
          await userService.setUserObjective("utdanning"); // TODO: Error handling
          dispatch(doUserGetMe());
          next(true);
        }}
        type={(objective === "education" && "hoved") || undefined}
      >
        Utdanning
      </Button>
    </section>
  );
};
