/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { authService, progressService, userService } from "../../../../api";
import { Button } from "../../../common/button/Button";
import { doJwtRefresh, doVerifyCode } from "../../../authSlice";
import { getProgress } from "../../../progressSlice";
import { ReactComponent as TusenTakk } from "../../../common/graphics/tusentakk.svg";
import { SURVEY_URLS } from "../../../../constants";
import { useDispatch, useQuery, useSelector } from "../../../../hooks";

export const Login = () => {
  const { qualtricsUuid } = useSelector((state) => state.user),
    failed = useSelector((state) => state.progress.failed),
    dispatch = useDispatch(),
    history = useHistory(),
    query: any = useQuery(),
    getUrl = async () => {
      const result = await authService.getLoginUrl();

      if (result !== null) window.location.href = result;
    };

  useEffect(() => {
    if (query.get("code") && query.get("state")) {
      if (query.get("state") === "apekatt") history.push("/chapters");
      dispatch(
        doVerifyCode({ code: query.get("code"), state: query.get("state") })
      )
        .then(() => dispatch(doJwtRefresh()))
        .then(() =>
          dispatch(getProgress()).then((result: any) => {
            if (
              result.payload?.chapter === 0 &&
              result.payload.pageNumber === undefined
            ) {
              progressService.put(0, "initialCheckIn");
              userService.addUserFeedbackData({ dummy: "" });
            }
          })
        );
    } else getUrl();
  }, [dispatch, history, query]);

  useEffect(() => {
    if (failed) {
      alert(
        "Du må registrere deg med en gyldig deltaker-ID før du kan sette igang."
      );
      authService.logout();
    }
  }, [failed]);

  return (
    failed !== true &&
    query.get("code") &&
    query.get("state") &&
    query.get("state") !== "apekatt" && (
      <section className={"privacy centered"}>
        <h1>Takk for at du vil delta i RØST!</h1>
        <p>Vi setter stor pris på ditt bidrag!</p>
        <TusenTakk />
        <br />
        <p>
          Du skal nå gå videre til spørreundersøkelsen. Det er kanskje ikke den
          kjekkeste delen av RØST, men det er utrolig verdifullt for
          forskningsprosjektet at du svarer.
        </p>
        <p>
          Sett av nok tid til å gjøre deg ferdig med spørreskjemaet (ca 15 min).
          Dersom du lukker spørreskjemaet før du er helt ferdig, vil alle
          svarene dine slettes og du må starte skjema på nytt for å gå videre.
        </p>
        <p>
          Husk at du tjener 100 poeng når du har fullført spørreskjema. Du har 1
          uke på deg.
        </p>
        <br />
        <Button
          onClick={() =>
            (window.location.href =
              SURVEY_URLS["1"] + "?q_uuid=" + qualtricsUuid)
          }
        >
          Fortsett til spørreundersøkelsen
        </Button>
      </section>
    )
  );
};
