import React from "react";
import "./main.less";
import uis from "../../../common/graphics/uis.jpg";

type Props = {
  withPadding?: boolean;
  children?: React.ReactNode;
};

export const AboutSection = ({ withPadding = true, children }: Props) => (
  <section className={withPadding ? "privacy padded" : "privacy"}>
    <h2>Invitasjon til å delta</h2>
    <p>
      Dette er en invitasjon til deg om å delta i et forskningsprosjekt hvor
      målet er å videreutvikle appen RØST
    </p>
    <p>
      I tillegg skal prosjektet gi ny kunnskap om unge jobb- og skolesøkende.
    </p>
    <p>Forskere fra Universitetet i Stavanger er ansvarlige for prosjektet.</p>
    <img src={uis} alt="UiS-logo" />
    <p>
      Du blir invitert til å delta fordi du er registrert hos
      Oppfølgingstjenesten. Du er ikke registret i prosjektet før du har gitt
      ditt samtykke til å delta.
    </p>
    <p className="title">Hva er RØST</p>
    <p>
      I RØST vil du bli introdusert for ulike verktøy som kan være nyttige for
      deg i din prosess mot jobb eller utdanning. Men vi trenger også din hjelp.
    </p>
    <p>
      Det er du som vet hvordan det er å være ung jobb- eller skolesøker. Derfor
      vil dine tanker om RØST være svært nyttige for andre i samme situasjon. I
      RØST vil vi derfor be deg om dine tilbakemeldinger. RØST betyr stemme, og
      vi trenger din stemme!
    </p>
    <p>
      Dine svar blir med på å forbedre appen, og vil kunne hjelpe andre i
      fremtiden.
    </p>
    <p className="title">Du får belønning</p>
    <p>
      Siden ditt bidrag betyr mye for oss, vil vi belønne deg. Ved å delta i
      RØST vil du tjene poeng som kan veksles inn i gavekort til en samlet verdi
      på 1000 kroner. Gavekortet er universelt og kan brukes på et stort utvalg
      butikker og tjenester over hele landet.
    </p>
    {children}
  </section>
);
