import { createAsyncThunk } from "@reduxjs/toolkit";

import { GiftCardData, Progress } from "../types";
import { progressService } from "../api";

export const getGiftcards = createAsyncThunk<{ giftcards: GiftCardData[] }>(
    "progress/getGiftcards",
    async (_, { getState }) => {
      const giftcards = (getState() as { progress: Progress }).progress
        .giftcards;
      let now = new Date().valueOf(),
        timestamp = localStorage.getItem("pendingGiftcardsTimestamp");

      if (timestamp === null) {
        localStorage.setItem("pendingGiftcardsTimestamp", now.toString());
        timestamp = now.toString();
      }

      // If giftcard is being generated, wait for two minutes (so as not to
      // trigger duplicate GoGift-requests)
      if (
        localStorage.getItem("pendingGiftcards") === "true" ||
        giftcards?.some((giftcard) => giftcard === null)
      ) {
        const diff = now - parseInt(timestamp as string);

        return new Promise<{ giftcards: GiftCardData[] }>((resolve) =>
          setTimeout(
            async () => {
              localStorage.setItem("pendingGiftcards", "false");
              localStorage.removeItem("pendingGiftcardsTimestamp");
              resolve(await progressService.getGiftcards());
            },
            diff > 120000 ? 0 : 120000 - diff
          )
        );
      } else {
        localStorage.setItem("pendingGiftcards", "false");
        localStorage.removeItem("pendingGiftcardsTimestamp");

        return await progressService.getGiftcards();
      }
    }
  ),
  initialGiftcardState = { giftcards: [], waitingForGiftcards: true };
