import React from "react";
import partners from "../../../common/graphics/partners.png";

export const AboutOverview = (args: { backButton: () => void }) => (
  <section className="settingsPage">
    <h1>Hvem har laget løsningen?</h1>
    {args.backButton()}
    <p>
      RØST er laget av en gruppe forskere ved Universitetet i Stavanger,
      Rogaland og Texas University Austin og Stavanger Universitetssjukehus og i
      samarbeid med ansatte i NAV
    </p>
    <img src={partners} alt="Samarbeidspartnere" />
    {/* <p>
      <a href="#">
        Mer informasjon finner du på nettsiden til forskningsprosjektet.
      </a>
    </p> */}
  </section>
);
