import { useEffect } from "react";

import { Assignment } from "../../../../../../sanity-schema";

export const useAutofill = (answers: string[], tasks: Assignment[]) => {
  useEffect(() => {
    answers &&
      tasks.forEach(({ id }, index) => {
        const el = document.querySelector(`#portal_target_for__${id}`);

        el && answers[index] !== undefined && (el.innerHTML = answers[index]);
      });
  }, [answers, tasks]);
};
