import React from "react";
import { RadioPanelGruppe } from "nav-frontend-skjema";

import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";

export const LinksButAlsoRadioButtons = (args: {
  locked: boolean;
  answers: string[];
  index: number;
  footnote?: string;
}) => {
  const { locked, answers, index, footnote } = args,
    dispatch = useDispatch(),
    linkNames = footnote?.split(",");

  return (
    <>
      <br />
      <RadioPanelGruppe
        name="work"
        className="choice full linkRadios"
        legend="Hva valgte du?"
        radios={
          linkNames?.slice(0, linkNames.length - 1).map((linkName) => ({
            label: linkName.split(" http")[0],
            value: "http" + linkName.split(" http")[1],
            disabled:
              answers[index] !== "http" + linkName.split(" http")[1] && locked,
          })) || []
        }
        checked={answers[index] === " " ? "" : answers[index]}
        onChange={(_, value) => {
          const copy = answers.slice();

          copy[index] = value;
          dispatch(setAnswers(copy));
        }}
      />
    </>
  );
};
