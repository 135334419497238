import React from "react";
import "./main.less";
import { Button, LinkButton } from "../../common/button/Button";
import { ContentProps as WizardContentProps } from "../../common/wizard/Wizard";
import { description } from "../../../type-guards";
import { format } from "../../../formatter";
import { Header } from "../../common/header/Header";
import { useWindowSize } from "../../../hooks";
import uis from "../../common/graphics/uis.png";

export const Home = (args: WizardContentProps) => {
  const { next, content } = args,
    large = useWindowSize().width >= 768;

  return (
    <>
      <Header
        title={content?.title?.toUpperCase()}
        description={description(content)}
        secondaryDescription={
          "Delta i forskningsprosjekt og kom nærmere målet."
        }
      />
      <section className="home">
        <nav>
          <Button onClick={() => next()}>Registrering</Button>
          {large && <br />}
          <LinkButton type={large ? "standard" : "hoved"} to="/login">
            Vært her før? <u>Logg inn</u>
          </LinkButton>
        </nav>
        <div>
          {format(
            "Delta i et forskningsprosjekt hvor målet er å videreutvikle appen RØST – for unge som søker jobb eller utdanning.\nVed å være med får du verktøy som kan hjelpe deg videre – og prosjektet får ny kunnskap om unge jobb- og skolesøkende.\nLa din stemme bli hørt!",
            false
          )}
          {<img src={uis} alt="Logoen til Universitetet i Stavanger" />}
          {format(
            "Lurer du på noe? Ta kontakt på ==hjelp@rost.app mailto:hjelp@rost.app==",
            false
          )}
        </div>
      </section>
    </>
  );
};
