import { useEffect, useState } from "react";
import { useSelector } from "../../../../hooks";

import { Timeout } from "../../../../types";

export const useFade = (id: string) => {
  const [visible, setVisible] = useState(false),
    fading = useSelector((state) => state.content.meta.fading);

  useEffect(() => {
    let timeout: Timeout = setTimeout(
      () => setVisible(!fading),
      fading ? 0 : 500
    );

    return () => clearTimeout(timeout);
  }, [id, fading]);

  return visible;
};
