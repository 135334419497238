import { useEffect, useRef } from "react";

import { contentService } from "../../../../api";
import { isSVGResult } from "../../../../type-guards";
import { setCache } from "../../../cacheSlice";
import { useDispatch, useSelector } from "../../../../hooks";

export const useIllustrations = () => {
  const svgsRef = useRef<Record<string, HTMLImageElement>>({}),
    cache = useSelector((state) => state.cache),
    dispatch = useDispatch();

  useEffect(() =>
    Object.entries(svgsRef.current).forEach(
      async ([id, ref]: [string, HTMLImageElement]) => {
        if (ref !== null) {
          const result =
            cache[id] !== undefined
              ? cache[id]
              : await contentService.get({
                  name: "illustration",
                  id: ref.alt,
                });

          if (isSVGResult(result)) {
            if (cache[id] === undefined) {
              dispatch(setCache({ id, result }));
            }
            ref.src = `data:image/svg+xml;base64,${btoa(result[0].SVG)}`;
            ref.className = result[0].fullWidth ? "showMe fullWidth" : "showMe";
          }
        }
      }
    )
  );

  return svgsRef;
};
