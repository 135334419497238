import { useEffect, useState } from "react";

import { contentService } from "../../../../../../api";
import { getRelativeId } from "../../../../../../utilities";
import { isPage } from "../../../../../../type-guards";
import { SanityPage } from "../../../../../../sanity-client";
import { Section } from "../../../../../../types";

export const useCherryPicking = (
  map: Record<string, number>,
  order: number,
  multiple: boolean,
  multipleish: boolean,
  footnote?: string
) => {
  const [pages, setPages] = useState<SanityPage[]>([]);

  useEffect(() => {
    let isCancelled = false;

    if (footnote)
      Promise.all(
        footnote.split(",").map((offset) =>
          contentService
            .get({
              name: Section.PAGE,
              id: getRelativeId(
                order,
                parseInt(offset) + (multiple || multipleish ? 0 : 1),
                map
              ),
            })
            .then((result) => (isPage(result[0]) && result[0]) || null)
        )
      ).then(
        (values: (SanityPage | null)[]) =>
          !isCancelled &&
          !values.some((value) => value === null) &&
          setPages(values as SanityPage[])
      );

    return () => {
      isCancelled = true;
    };
  }, [footnote, multipleish, map, multiple, order]);

  return pages;
};
