import React from "react";
import {
  Settings,
  Next,
  LockedFilled,
  Saving,
  VolumeUp,
} from "@navikt/ds-icons";

import { NavVariant } from "./NavVariant";

export const SettingsIcon = () => <Settings className="settings" />,
  NextIcon = () => <Next className="next" />,
  LockedIcon = () => <LockedFilled className="locked" />,
  SavingIcon = () => <Saving className="saving" />,
  SoundIcon = () => <VolumeUp className="sound" />,
  PersonIcon = () => (
    <NavVariant
      className="person"
      viewBox="0 0 14 24"
      pathData={`m5 4c0.55228 0 1-0.44772 1-1s-0.44772-1-1-1-1 0.44772-1 1 0.44772 1 1 1zm0 2c1.6568 0 3-1.3432 3-3s-1.3432-3-3-3-3 1.3432-3 3 1.3432 3 3 3zm-5 3c0-1.1046 0.89543-2 2-2h2v2h-2v7h2v8h-2v-6h-2v-9zm6 0h1.3757l3.421 7h-4.7967v8h2v-6h6.0002l-4.8276-9.8782c-0.33549-0.68648-1.0328-1.1218-1.7969-1.1218h-1.3757v2z`}
      fillRule="evenodd"
    />
  ),
  PointsIcon = () => (
    <NavVariant
      className="points"
      viewBox="0 0 24 22"
      pathData={`m18.998 0.686c-1.6224 0-3.0648 0.80294-3.9805 2.0312-0.0066-2.64e-5 -0.01292-0.00195-0.01953-0.00195-2.7449 0-5 2.2802-5 5.0762h-9.998v14.209h24v-14.209h-0.42774c0.27086-0.62333 0.42578-1.3085 0.42578-2.0312 0-2.7959-2.255-5.0742-5-5.0742zm0 2c1.6454 0 3 1.3409 3 3.0742 0 0.79186-0.29185 1.4932-0.75586 2.0312h-1.2441c0-1.9707-1.1314-3.6654-2.7539-4.5059 0.49702-0.36814 1.0959-0.59961 1.7539-0.59961zm-4 2.0293c1.6454 0 3 1.3429 3 3.0762 0 1.7332-1.3546 3.0742-3 3.0742-1.6453 0-3-1.341-3-3.0742 0-1.7333 1.3547-3.0762 3-3.0762zm-12.998 5.0762h8.4082c0.25897 0.60724 0.63115 1.1535 1.0898 1.6113v2.9922h-9.498zm19.996 0h0.003907v4.6035h-9.502v-2.2383c0.73938 0.43887 1.5875 0.70898 2.5 0.70898 1.6204 0 3.0568-0.80563 3.9727-2.0312 0.0093 6.7e-5 0.01805 2e-3 0.02734 2e-3 1.1263 0 2.1586-0.39899 2.998-1.0449zm-19.996 5.6035h9.498v4.6055h-9.498zm10.498 0h9.502v4.6055h-9.502z`}
    />
  ),
  CheckIcon = () => (
    <NavVariant
      className="check"
      viewBox="0 0 39 31"
      pathData={`m34.56 0.65234 4.3138 4.4067-26.911 25.896-11.463-10.733 4.3017-4.4185 7.1485 6.5312 22.61-21.683z`}
    />
  );
