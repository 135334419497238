import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./main.less";
import { Points } from "../common/points/Points";
import { Progress as ProgressComponent } from "../common/progress/Progress";
import { Progress, Section } from "../../types";
import { ReactComponent as PiggyBank } from "../common/graphics/piggybank.svg";
import { useSelector } from "../../hooks";

export const GiftCardCompact = ({ progress }: { progress: Progress }) => {
  const version = useSelector((state) => state.user.version),
    content = useSelector((state) => state.content.sanity),
    [visible, setVisible] = useState(false),
    [points, setPoints] = useState(0),
    pointsWrapper = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setTimeout(() => {
      pointsWrapper.current &&
        setPoints(parseInt(pointsWrapper.current.innerText));
      setVisible(true);
    }, 750);
  }, []);

  useEffect(() => {
    pointsWrapper.current &&
      setPoints(parseInt(pointsWrapper.current.innerText));
  }, [progress]);

  return (
    <a href="/giftcard" style={{ textDecoration: "none" }}>
      <div className={classNames("giftCard", "soft", visible && "visible")}>
        <PiggyBank />
        <p className="title">
          Gavekort på opp til 1000 kroner
          <br />
          <span className="subtitle typo-undertekst">
            <span ref={pointsWrapper}>
              <Points
                inMilestone={false}
                headless={true}
                version={version}
                chapters={content[Section.CHAPTERS]}
                completed={progress.completed}
              />
            </span>{" "}
            av 1000 mulige poeng
          </span>
        </p>
        <ProgressComponent
          value={points / 10}
          complete={false}
          colorOverride="--atNavOransjeLighten90"
        />
      </div>
    </a>
  );
};
