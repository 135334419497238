import { useEffect } from "react";

import { setIds } from "../../../contentSlice";
import { useDispatch } from "../../../../hooks";

export const useSkip = (
  skip: boolean,
  movedBack: boolean,
  chapterId: string,
  next?: string,
  prev?: string
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    skip &&
      dispatch(
        setIds({
          id: movedBack && prev ? prev : next || "milestone",
          parentId: chapterId,
        })
      );
  }, [skip, dispatch, next, chapterId, movedBack, prev]);
};
