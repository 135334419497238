import React, { useEffect, useState } from "react";

import { format } from "../../../../formatter";
import { useIllustrations } from "./hooks";

export const useFormat = (
  body: string = "",
  varies: boolean,
  variant?: string
): [
  React.ReactNode,
  React.MutableRefObject<Record<string, HTMLImageElement>>
] => {
  const svgsRef = useIllustrations(),
    [formattedBody, setFormattedBody] = useState<React.ReactNode>(null);

  useEffect(() => {
    setFormattedBody(format((varies && variant) || body, svgsRef));

    return () => setFormattedBody(null);
  }, [varies, body, svgsRef, variant]);

  return [formattedBody, svgsRef];
};
