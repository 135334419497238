import { Props as MilestoneProps } from "./features/milestone/Milestone";
import { Props as WizardProps } from "./features/common/wizard/Wizard";
import {
  SanityChapter,
  SanityContent,
  SanityGeneral,
  SanityPage,
  SanityIllustration,
} from "./sanity-client";
import { Section } from "./types";

export const isApiError = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): value is {
    response: { data: unknown };
  } => value.response && value.response.data,
  isSVGResult = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): value is [SanityIllustration] => value[0] && value[0].SVG,
  isSection = (value: string): value is Section =>
    Object.values(Section).indexOf(value as Section) !== -1,
  isChapter = (value: SanityContent): value is SanityChapter =>
    "_type" in value && value._type === Section.CHAPTERS,
  areChapters = (value: SanityContent[]): value is SanityChapter[] =>
    value.every((item) => "_type" in item && item._type === Section.CHAPTERS),
  isGeneral = (value: SanityContent | undefined): value is SanityGeneral =>
    value !== undefined && "_type" in value && value._type === Section.GENERAL,
  isPage = (
    value: SanityContent | MilestoneProps | WizardProps | false | undefined
  ): value is SanityPage =>
    !!value &&
    value !== undefined &&
    "_type" in value &&
    value._type === Section.PAGE,
  isMilestone = (
    value: SanityContent | MilestoneProps | WizardProps | false | undefined
  ): value is MilestoneProps =>
    !!value && value !== undefined && "milestoneBody" in value,
  arePages = (
    value: (SanityContent | MilestoneProps)[]
  ): value is SanityPage[] =>
    value.every((item) => "_type" in item && item._type === Section.PAGE),
  isWizard = (
    value: SanityContent | MilestoneProps | WizardProps | false | undefined
  ): value is WizardProps => !!value && value !== undefined && "steps" in value,
  description = (_value: SanityContent | undefined) =>
    (((value): value is SanityContent & { description: string } =>
      value !== undefined && "description" in value)(_value) &&
      _value.description) ||
    undefined;
