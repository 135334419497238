import React, { ReactNode, useEffect, useRef } from "react";
import { renderToStaticMarkup as toStatic } from "react-dom/server";
import classNames from "classnames";

import "./main.less";
import { format } from "../../formatter";
import { getProgress } from "../progressSlice";
import { GIFT_CARD_MODULES } from "../../constants";
import { LinkButton } from "../common/button/Button";
import { ReactComponent as BigCheck } from "../common/graphics/bigcheck.svg";
import { Sparkles } from "./sparkles/Sparkles";
import { useSelector, useDispatch } from "../../hooks";

export interface Props {
  milestoneHeading?: string;
  milestoneBody?: string;
  points?: number | JSX.Element;
  children?: ReactNode;
  fullscreen?: true;
  noPoints?: true;
  cta?: ReactNode;
}

export const Milestone = (args: Props) => {
  const { milestoneBody, points, fullscreen, cta, noPoints } = args,
    isLoggedIn = useSelector((state) => state.auth).user.id !== null,
    chapter = useSelector((state) => state.progress.chapter),
    dispatch = useDispatch(),
    wrapper = useRef<HTMLHeadingElement>(null);
  let formattedMileStoneBody = milestoneBody && format(milestoneBody, false);

  useEffect(() => {
    !noPoints && isLoggedIn && dispatch(getProgress());
  }, [dispatch, noPoints, isLoggedIn]);

  useEffect(() => {
    if (
      points !== undefined &&
      wrapper.current &&
      wrapper.current.querySelectorAll("p").length > 1
    ) {
      const graphic = wrapper.current.querySelectorAll("p")[1];

      graphic.className = "graphic";
      graphic.setAttribute(
        "data-points",
        typeof points === "number" ? points.toString() : toStatic(points)
      );
      graphic.style.backgroundImage = `url("data:image/svg+xml;utf8,${encodeURIComponent(
        toStatic(
          <Sparkles>
            <BigCheck x="54.5" y="34.5" className="static" />
          </Sparkles>
        )
      )}")`;
    }
  }, [formattedMileStoneBody, points]);

  return (
    <section
      className={classNames(
        "milestone",
        fullscreen && "fullscreen",
        noPoints && "noPoints"
      )}
    >
      <h1>{args.milestoneHeading}</h1>
      <h2 ref={wrapper}>
        {formattedMileStoneBody}
        <p></p>
      </h2>
      {GIFT_CARD_MODULES[chapter.toString()] !== undefined && (
        <LinkButton to="/giftcard">Hent gavekort</LinkButton>
      )}
      <br />
      <br />
      {cta}
    </section>
  );
};
