import React from "react";
import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";

export const Time = (args: {
  locked: boolean;
  answers: string[];
  index: number;
}) => {
  const { locked, answers, index } = args,
    dispatch = useDispatch(),
    tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <input
      disabled={locked}
      className="timePicker"
      type="datetime-local"
      onChange={(event) => {
        const copy = answers.slice();

        copy[index] = event.target.value;
        dispatch(setAnswers(copy));
      }}
      min={tomorrow.toISOString().slice(0, 16)}
      value={
        answers[index] === " " || answers[index] === ""
          ? tomorrow.toISOString().slice(0, 16)
          : answers[index]
      }
    />
  );
};
