import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";
import { Timeout } from "../../../../../../types";

export const Stars = (args: {
  locked: boolean;
  answers: string[];
  index: number;
}) => {
  const { locked, answers, index } = args,
    dispatch = useDispatch(),
    [hint, setHint] = useState(false);

  useEffect(() => {
    let timeout: Timeout = setTimeout(() => {
      answers[index] === " " && setHint(true);
    }, 5000);

    return () => {
      setHint(false);
      clearTimeout(timeout);
    };
  }, [answers, index]);

  return (
    <p className={classNames("stars", locked && "disabled")}>
      {Array.from({ length: 5 }, (_, starIndex) => (
        <span
          className={classNames(
            "star",
            parseInt(answers[index]) >= starIndex + 1 && "selected"
          )}
          key={starIndex}
          onClick={() => {
            const copy = answers.slice();

            copy[index] = (starIndex + 1).toString();
            dispatch(setAnswers(copy));
          }}
        >
          ★
        </span>
      ))}
      {hint && (
        <>
          <br />
          <span className="typo-feilmelding">Velg et antall stjerner</span>
        </>
      )}
    </p>
  );
};
