import React from "react";
import classNames from "classnames";

import "./main.less";

export type Color = "green" | "yellow" | "pink" | "blue" | undefined;

export type Alignment = "left" | "right" | undefined;

export interface Props {
  children: React.ReactNode;
  align: Alignment;
  color: Color;
  mod?: boolean | unknown;
}

export const nextQuoteColor: (color: Color) => Color = (color) => {
    const order: Color[] = ["blue", "yellow", "green", "pink"];

    if (color === undefined) return order[0];
    return order.indexOf(color) + 1 < order.length
      ? order[order.indexOf(color) + 1]
      : order[0];
  },
  Quote = ({ children, color, align, mod }: Props) => (
    <span
      className={classNames(
        "quote",
        typeof mod === "boolean" && mod && "alternative"
      )}
    >
      <q className={classNames(color, align || "left")}>
        {mod ? `- ${children}` : `“${children}”`}
      </q>
    </span>
  );
