import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { Mood } from "../moods";
import { ReactComponent as Bra } from "../../../common/graphics/bra.svg";
import { ReactComponent as Darlig } from "../../../common/graphics/darlig.svg";
import { ReactComponent as Fantastisk } from "../../../common/graphics/fantastisk.svg";
import { ReactComponent as Forferdelig } from "../../../common/graphics/forferdelig.svg";
import { ReactComponent as Okay } from "../../../common/graphics/okay.svg";
import { useSelector } from "../../../../hooks";

export const Feedback = (args: WizardContentProps) => {
  const lastReportedMood = useSelector(
    (state) => state.progress.lastReportedMood
  );

  return (
    <>
      <Header homeButton={true} ancillaryButtons={true} collapsed={true} />
      {lastReportedMood !== undefined && (
        <div className="feedback">
          {Object.values(Mood)[lastReportedMood] === Mood.M0 && (
            <>
              <Forferdelig />
              <p>
                <strong>Det var trist å høre.</strong>
              </p>
              <p>Kanskje RØST kan hjelpe deg å få humøret opp et par hakk.</p>
            </>
          )}
          {Object.values(Mood)[lastReportedMood] === Mood.M1 && (
            <>
              <Darlig />
              <p>
                <strong>Det var trist å høre.</strong>
              </p>
              <p>Kanskje RØST kan hjelpe deg til å få det litt bedre.</p>
            </>
          )}
          {Object.values(Mood)[lastReportedMood] === Mood.M2 && (
            <>
              <Okay />
              <p>
                <strong>Ok!</strong>
              </p>
              <p>
                Du trenger ikke alltid føle deg fantastisk, men du kan fortsatt
                lære deg nye ting.
              </p>
            </>
          )}
          {Object.values(Mood)[lastReportedMood] === Mood.M3 && (
            <>
              <Bra />
              <p>
                <strong>Godt å høre!</strong>
              </p>
              <p>Da setter vi i gang.</p>
            </>
          )}
          {Object.values(Mood)[lastReportedMood] === Mood.M4 && (
            <>
              <Fantastisk />
              <p>
                <strong>Godt å høre!</strong>
              </p>
              <p>Da kjører vi i gang.</p>
            </>
          )}
          {args.children()}
        </div>
      )}
    </>
  );
};
