import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../common/wizard/Wizard";
import { ReactComponent as Jobb } from "../common/graphics/jobb.svg";
import { ReactComponent as Utdanning } from "../common/graphics/utdanning.svg";

export const Confirmation = (args: WizardContentProps) => (
  <section className="objective">
    <h1>
      Bra!
      <br />
      Du ønsker å komme i gang med {args.toggle ? "utdanning" : "jobb"}.
    </h1>
    <p>
      Når vi vet hva du ønsker å komme i gang med, kan vi lettere tilpasse appen
      for deg.
    </p>
    {args.toggle ? <Utdanning /> : <Jobb />}
    {args.children()}
  </section>
);
