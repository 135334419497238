export const useCategory = (category?: string) => {
  const split = category === "Splitt" ? category : undefined;
  const greeting = category === "Velkommen tilbake" ? category : undefined;
  const greeting2 =
    category === "Skjema 2" || category === "Velkommen tilbake"
      ? category
      : undefined;
  const example =
    category === "Eksempel" ||
    category === "Eksempel (alternativ)" ||
    category === "Betinget eksempel"
      ? category
      : undefined;
  const overview = category === "Overblikk" ? category : undefined;
  const assignment =
    category === "Oppgave" || category === "Betinget oppgave"
      ? category
      : undefined;

  return { split, greeting, greeting2, example, overview, assignment };
};
