import React, {
  FunctionComponent,
  useEffect,
  useState,
  createElement,
} from "react";

import "./main.less";
import { AboutOverview } from "./AboutOverview";
import { authService } from "../../../../api";
import { Button } from "../../../common/button/Button";
import { Contact } from "../../../common/contact/Contact";
import { ContentOverview } from "./ContentOverview";
import { Header } from "../../../common/header/Header";
import { MoodOverview } from "./MoodOverview";
import { NotificationOverview } from "./NotificationOverview";
import { PrivacyOverview } from "./PrivacyOverview";
import { ReactComponent as Alarm } from "../../../common/graphics/alarm.svg";
import { ReactComponent as Checklist } from "../../../common/graphics/checklist.svg";
import { ReactComponent as Info } from "../../../common/graphics/info.svg";
import { ReactComponent as Pencil } from "../../../common/graphics/pencil.svg";
import { ReactComponent as Smile } from "../../../common/graphics/smile.svg";
import { useSelector } from "../../../../hooks";

export const Settings = () => {
  const [section, setSection] = useState("main"),
    moods = useSelector((state) => state.progress.moods),
    backButton = () => (
      <Button onClick={() => setSection("main")}>Tilbake</Button>
    ),
    menu = [
      ["content", Pencil, "Oversikt", "Slik fungerer RØST"],
      ["notifications", Alarm, "Varslinger", "Styr SMS-påminnelser"],
      ["mood", Smile, "Humøroversikt", "Oversikt over hvordan du har hatt det"],
      ["about", Info, "Om løsningen", "Hvem har laget RØST?"],
      ["privacy", Checklist, "Samtykke", "Informasjon om ditt samtykke"],
    ] as [string, FunctionComponent, string, string][];

  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Header collapsed homeButton ancillaryButtons inSettings />
      {section === "main" && (
        <section className="settingsPage">
          <h1>Innstillinger</h1>
          <p className="loggedInAs">Du er logget inn.</p>
          <Button type="hoved" onClick={async () => await authService.logout()}>
            Logg ut
          </Button>
          <ul className="menu">
            {menu.map(([section, svg, title, description]) => (
              <li key={section} onClick={() => setSection(section)}>
                <div className="svgWrapper">{createElement(svg)}</div>
                <strong>{title}</strong>
                {description}
              </li>
            ))}
          </ul>
        </section>
      )}
      {section === "content" && <ContentOverview backButton={backButton} />}
      {section === "notifications" && (
        <NotificationOverview backButton={backButton} />
      )}
      {section === "mood" && moods && (
        <MoodOverview backButton={backButton} moods={moods} />
      )}
      {section === "about" && <AboutOverview backButton={backButton} />}
      {section === "privacy" && <PrivacyOverview backButton={backButton} />}
      <Contact />
    </>
  );
};
