import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { authService, userService } from "../api";
import { isApiError } from "../type-guards";
import { User } from "../types";

export const logOutUser = createAsyncThunk("user/logout", authService.logout),
  doUserGetMe = createAsyncThunk(
    "user/getMeStatus",
    async (_, { getState, rejectWithValue }) => {
      try {
        return await userService.getMe();
      } catch (err) {
        return rejectWithValue(isApiError(err) && err.response.data);
      }
    }
  ),
  initialState: User = {
    id: null,
    version: null,
    choiceOfDirection: null,
    mobile: null,
    qualtricsUuid: null,
  },
  { reducer: userSliceReducer } = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(
        doUserGetMe.fulfilled,
        (state, { payload }: { payload: { data: null | User } }) => {
          if (payload.data !== null) {
            state.id = payload.data.id;
            state.version = payload.data.version;
            state.choiceOfDirection = payload.data.choiceOfDirection;
            state.mobile = payload.data.mobile;
            state.qualtricsUuid = payload.data.qualtricsUuid;
          }
        }
      );
    },
  });
