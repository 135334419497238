import React from "react";
import classNames from "classnames";
import NavFrontendSpinner from "nav-frontend-spinner";

import "./main.less";
import { Assignment } from "./assignment/Assignment";
import { Button } from "../../../common/button/Button";
import { Example } from "./example/Example";
import { Objective } from "../../../../types";
import { Overview } from "./overview/Overview";
import { SURVEY_URLS } from "../../../../constants";
import { useFormat, useVideo, useCategory, useAudio, useFade } from "./hooks";
import { useSelector } from "../../../../hooks";
import * as Sanity from "../../../../sanity-client";

type Props = Sanity.SanityPage & {
  tasks: Sanity.SanityAssignment[];
  chapter: Sanity.SanityChapter;
  path: Objective;
  map: Record<string, number>;
};

export const Body = (props: Props) => {
  const { audio, category, ingress, title, video, tasks, order, map, path } =
    props;
  const uuid = useSelector((state) => state.user.qualtricsUuid);
  const choiceOfDirection = useSelector(
    (state) => state.user.choiceOfDirection
  );
  const isWork = choiceOfDirection === "jobb";

  const AudioButton = useAudio(audio, category);
  const { VideoElement, videoDone } = useVideo(video);
  const visible = useFade(props._id);

  const { split, greeting, greeting2, example, overview, assignment } =
    useCategory(category);

  const tag = (example || assignment || "")
    .replace("(alternativ)", "")
    .replace("Betinget o", "O")
    .replace("Betinget e", "E");

  const varies = !!split || !!assignment;
  const variant = props.path && props[`body_${props.path}`];
  const [formattedBody, svgsRef] = useFormat(props.body, varies, variant);

  const survey = uuid && `${SURVEY_URLS[greeting ? "3" : "2"]}?q_uuid=${uuid}`;
  const goToSurvey = () => {
    if (survey) window.location.href = survey;
  };

  const className = classNames(
    "body",
    visible && "visible",
    (greeting || greeting2) && "greet",
    (greeting || greeting2) &&
      (!video || (video && !videoDone)) &&
      "hideNavigation",
    assignment && "assignment"
  );

  const completedChapters = useSelector((state) => state.progress.completed);
  const droppedChapters = useSelector((state) => state.progress.dropped);

  // Disable survey when on "Skjema 2" and chapter 4 has been completed before
  const isSurveyDisabled =
    (greeting2 === "Skjema 2" && completedChapters?.includes(4)) ||
    (greeting2 === "Velkommen tilbake" && completedChapters?.includes(5)) ||
    (props?.chapter?.order > 0 &&
      droppedChapters?.includes(props?.chapter?.order))
      ? true
      : false;

  return (
    <>
      {!visible && <NavFrontendSpinner />}
      <span className={className}>
        <p className="category">{tag}</p>
        <h2>
          {title.indexOf("|") === -1 ? title : title.split("|")[isWork ? 0 : 1]}
          {!ingress && example && <span className="space" />}
        </h2>
        {audio && <AudioButton />}
        {example && props.example ? (
          <>
            {ingress && <p children={[ingress, <span className="space" />]} />}
            <Example props={props} svgsRef={svgsRef} />
          </>
        ) : null}
        {formattedBody}
        {assignment && <Assignment {...{ tasks, order, map, path, svgsRef }} />}
        {overview && <Overview />}
        {(greeting || greeting2) && !video && (
          <Button
            className="survey"
            type="hoved"
            onClick={goToSurvey}
            disabled={isSurveyDisabled}
          >
            Spørreskjema
          </Button>
        )}
        {video && <VideoElement />}
      </span>
    </>
  );
};
