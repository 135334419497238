import React from "react";
import "./main.less";
import { Summary } from "../../../common/summary/Summary";

type Props = {
  withPadding?: boolean;
  includeNextPageShort?: boolean;
  children?: React.ReactNode;
};

export const AccessSection = ({
  withPadding = true,
  includeNextPageShort = true,
  children,
}: Props) => (
  <section className={withPadding ? "privacy padded" : "privacy"}>
    <h2>Hva innebærer det å delta?</h2>
    <br />
    <Summary />
    <p>
      Deltakelse innebærer også at vi innhenter følgende informasjon fra
      Statistisk sentralbyrå og fylkeskommunen om alle som deltar: Alder, kjønn,
      bostedskommune, fødeverdensdel, resultater fra nasjonale prøver på 8. og
      9. trinn., hvilken ungdomsskole du gikk på, fravær og avgangskarakterer på
      ungdomsskolen, søking og deltakelse i videregående opplæring,
      utdanningsnivå og -type, yrkesdeltakelse, inntekt, ukentlig arbeidstid,
      yrkeskategori, jobbsøking/deltakelse på arbeidsmarkedstiltak, frem til
      2034. I tillegg innhenter vi dine foreldres inntektsnivå, utdanningsnivå
      og fødeverdensdel. Vi vil også kartlegge hvordan du bruker appen.
    </p>
    <p>
      Mens prosjektet pågår, lagrer vi ditt personnummer og telefonnummer
      adskilt fra øvrig data. Du må benytte bankID for å logge på RØST.
    </p>
    {includeNextPageShort ? (
      <p>
        På neste side skal vi se mer på hvordan vi ivaretar ditt personvern.
      </p>
    ) : null}
    {children}
  </section>
);
