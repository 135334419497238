import React from "react";
import { RadioPanelGruppe } from "nav-frontend-skjema";
import { setAnswers } from "../../../../../contentSlice";
import { useDispatch } from "../../../../../../hooks";

export const KnowOrDontKnow = (args: {
  locked: boolean;
  answers: string[];
  index: number;
}) => {
  const { locked, answers, index } = args,
    dispatch = useDispatch();

  return (
    <RadioPanelGruppe
      name="work"
      className="choice"
      legend=""
      radios={[
        {
          label: "Ja, kjenner til",
          value: "true",
          disabled: answers[index] === "false" && locked,
        },
        {
          label: "Nei, det var nytt",
          value: "false",
          disabled: answers[index] === "true" && locked,
        },
      ]}
      checked={answers[index] === " " ? "" : answers[index]}
      onChange={(_, value) => {
        const copy = answers.slice();

        copy[index] = value;
        dispatch(setAnswers(copy));
      }}
    />
  );
};
