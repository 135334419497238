import React from "react";
import { LinkButton } from "../common/button/Button";
import { Milestone } from "./Milestone";

export const OnBoardingComplete = () => (
  <Milestone
    fullscreen={true}
    noPoints={true}
    cta={<LinkButton to="/">Kom i gang</LinkButton>}
    milestoneHeading="Du er klar til start!"
    milestoneBody="Du kan nå ta i bruk appen!&#10;Hver uke vil du få påminnelse når en ny modul er tilgjengelig.&#10;Hver modul tar ca. 15-30 minutter å gjennomføre.&#10;Hvis du blir avbrutt følger du bare samme lenke, så kommer du tilbake til der du sluttet."
  />
);
