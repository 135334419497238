import React from "react";
import classNames from "classnames";

import { getImageURL } from "../../../../../../utilities";
import { setAnswers } from "../../../../../contentSlice";
import { useCherryPicking } from "./useCherryPicking";
import { useDispatch } from "../../../../../../hooks";

export const Picker = (args: {
  locked: boolean;
  answers: string[];
  index: number;
  order: number;
  map: Record<string, number>;
  path: string;
  footnote?: string;
  type?: string;
}) => {
  const { locked, answers, index, footnote, order, map, type, path } = args,
    dispatch = useDispatch(),
    multiple = type === "Flervelger",
    multipleish = type === "Flervelgeraktig",
    pages = useCherryPicking(map, order, multiple, multipleish, footnote);

  return (
    <ul
      className={classNames(
        "picker",
        (multiple || multipleish) && "multiple",
        locked && "disabled"
      )}
    >
      {pages.map((props, pageIndex) => {
        const pageIndexString = (pageIndex + 1).toString();

        return (
          <li
            className={classNames(
              "pick",
              multiple
                ? answers[index].indexOf(pageIndexString) !== -1 && "selected"
                : parseInt(answers[index]) === pageIndex + 1 && "selected"
            )}
            key={pageIndex + 1}
            onClick={() => {
              const copy = answers.slice();

              if (multiple) {
                if (copy[index].indexOf(`${pageIndexString},`) !== -1)
                  copy[index] = copy[index].replace(`${pageIndexString},`, "");
                else copy[index] = `${copy[index]}${pageIndexString},`;
              } else copy[index] = pageIndexString;
              dispatch(setAnswers(copy));
            }}
          >
            {props.exampleImage && (
              <div
                className="image"
                style={{
                  backgroundImage: `url(${getImageURL(
                    props.exampleImage.asset._ref
                  )})`,
                }}
                title={props.exampleImage.caption}
              />
            )}
            <strong>{multiple || multipleish ? "" : props.title}</strong>
            {multiple || multipleish
              ? path === "work"
                ? props.body_work
                : props.body_education
              : props.exampleTitle}
          </li>
        );
      })}
    </ul>
  );
};
