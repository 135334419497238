import { CDN_URL, MONTH_NAMES } from "./constants";
import { isChapter, isPage } from "./type-guards";
import { Items, Objective, Progress, Section } from "./types";
import { SanityContent, SanityPage } from "./sanity-client";
import config from "./sanity-config.json";

type Map = Record<string, number>;

export const getMassage = (payload: SanityContent[]) =>
    payload.reduce((items: Items<SanityContent>, sanityContent) => {
      const index = sanityContent._id;

      if (isChapter(sanityContent) || isPage(sanityContent))
        sanityContent.order = sanityContent.order - 1;
      index && (items[index] = sanityContent);

      return items;
    }, {}),
  getProgress = (
    index: number,
    id: string,
    progress: Progress,
    pageCounts?: Map
  ) => {
    if (progress.chapter >= index) {
      if (progress.pageNumber && pageCounts?.[id])
        return progress.pageNumber / (pageCounts[id] / 100);

      return 0;
    }

    return -1;
  },
  getPageCounts = (state: Record<Section, Items<SanityContent>>) =>
    Object.values(state[Section.PAGE]).reduce((counts: Map, content) => {
      let ref = isPage(content) && content.parent._ref;

      ref !== false &&
        (counts[ref] === undefined
          ? (counts[ref] = 1)
          : (counts[ref] = counts[ref] += 1));

      return counts;
    }, {}),
  getImageURL = (id: string) =>
    `${CDN_URL}images/${config.projectId}/${config.dataset}/${id.replace(
      /image-|-jpg|-png/g,
      ""
    )}.${id.indexOf("-jpg") !== -1 ? "jpg" : "png"}`,
  getRelativeId = (index: number, displacement: number, map: Map) =>
    Object.entries(map).find((entry) => entry[1] === index + displacement)?.[0],
  getFormattedDate = (date: number) =>
    `${new Date(date).getDate()}. ${MONTH_NAMES[new Date(date).getMonth()]}`,
  getFormattedDateFull = (date: number) =>
    `${new Date(date).getDate()}. ${
      MONTH_NAMES[new Date(date).getMonth()]
    } ${new Date(date).getFullYear()}`,
  getPageContentById = (
    id: string,
    group: SanityPage[],
    raw: Record<string, string>,
    path: Objective,
    map: Map
  ): SanityPage => {
    const byId = group.find(({ _id }) => _id === id) as SanityPage,
      proxy =
        byId?.order !== undefined &&
        byId.title.indexOf("proxy__") === 0 &&
        ({
          ...group.find(
            ({ _id }) =>
              _id ===
              (getRelativeId(
                byId.order,
                parseInt(
                  raw[byId.title.replace("proxy__", "").replace("X", path)]
                ),
                map
              ) || id)
          ),
        } as SanityPage | undefined);

    return proxy ? { ...proxy, order: byId.order, condition: undefined } : byId;
  };
