import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authSliceReducer as authReducer } from "./features/authSlice";
import { contentSliceReducer as contentReducer } from "./features/contentSlice";
import { progressSliceReducer as progressReducer } from "./features/progressSlice";
import { userSliceReducer as userReducer } from "./features/userSlice";
import { cacheSliceReducer as cacheReducer } from "./features/cacheSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: [],
  },
  persistedProgressReducer = persistReducer(persistConfig, progressReducer);

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    progress: persistedProgressReducer,
    content: contentReducer,
    cache: cacheReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      /* serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }, */
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
