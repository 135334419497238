import React from "react";
import "./main.less";

export const Techniques = () => (
  <section className="techniques">
    <h2>Teknikker som kan hjelpe mot negative tanker</h2>
    <p>Dette er noen teknikker du kan bruke hvis du blir veldig nervøs:</p>
    <ol>
      <li>
        <strong>Rolig pusting:</strong> Pust inn gjennom nesen i 4 sekunder,
        hold pusten i 4 sekunder, pust ut rolig gjennom munnen i 8 sekunder. Du
        kan telle slik inni deg: Pust inn to-tre-fir, hold fast to-tre-fir, pust
        ut to-tre-fir-fem-seks-sju-ått.
      </li>
      <li>
        <strong>Legg merke til detaljer i omgivelsene:</strong> Hvilke farger
        ser du? Hva har folk på seg?
      </li>
      <li>
        <strong>Trygt sted:</strong> Tenk deg et sted eller en situasjon hvor du
        føler deg rolig, avslappet, og veltilpass. Øv deg på å hente frem denne
        situasjonen mentalt når du forbereder deg eller er midt oppi noe som er
        skummelt eller ubehagelig.
      </li>
      <li>
        <strong>Husk at alle skumle situasjoner går over!</strong>
      </li>
    </ol>
  </section>
);
