import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";

import "./main.less";
import { CardInner } from "./CardInner";
import { GIFT_CARD_MODULES } from "../../../../constants";
import { Progress } from "../../../common/progress/Progress";
import { Status } from "../../../common/status/Status";
import { Items, Timeout } from "../../../../types";
import { useWindowSize } from "../../../../hooks";
import * as icons from "../../../common/icons/Icons";
import { Points } from "../../../common/points/Points";
import { SanityContent } from "../../../../sanity-client";

interface Props {
  index: number;
  title?: string;
  description?: string;
  progress: number;
  completed?: number[] | undefined;
  version: number | null;
  chapters: Items<SanityContent>;
  complete?: boolean;
  dropped?: boolean;
  launch?: () => void;
}

export const Card = (args: Props) => {
  const {
      index,
      progress,
      completed,
      version,
      chapters,
      dropped,
      launch,
      complete,
    } = args,
    [mode, setMode] = useState<"off" | "dim" | "on">("off"),
    stage = complete ? "done" : progress < 0 ? "locked" : "open",
    active = stage !== "locked",
    withGiftCard = GIFT_CARD_MODULES[index] !== undefined,
    compact = useWindowSize().width < 768,
    { SavingIcon, NextIcon, CheckIcon, LockedIcon } = icons;

  useEffect(() => {
    let timeout: Timeout = setTimeout(
      () => setMode(active ? "on" : "dim"),
      100 + 50 * index
    );

    return () => clearTimeout(timeout);
  });

  return (
    <li
      onClick={() => active && launch && launch()}
      className={classNames(
        compact ? "card compact soft" : "card softer",
        mode,
        stage,
        withGiftCard && "withGiftCard"
      )}
      data-count={index}
    >
      <span className="dimmable">
        {!compact && active && (
          <Progress value={progress} complete={complete || false} />
        )}
        {stage === "done" && <CheckIcon />}
        {active ? (
          (compact || stage === "open") && <NextIcon />
        ) : (
          <LockedIcon />
        )}
        <CardInner {...{ ...args, stage }} />
      </span>
      {withGiftCard && (
        <Status
          Icon={SavingIcon}
          fulfilled={complete !== undefined && complete}
          compact={compact}
        >
          {index === 5 ? (
            <>
              Gavekort på {GIFT_CARD_MODULES[index]} kroner{" "}
              {stage === "done" ? "" : "når du svarer"}
            </>
          ) : dropped ? (
            <>Gavekort på opp til {GIFT_CARD_MODULES[index]} kroner</>
          ) : (
            <>Gavekort på opp til {GIFT_CARD_MODULES[index]} kroner</>
          )}
        </Status>
      )}
    </li>
  );
};
