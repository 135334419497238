import axios from "axios";

import { API_BASE_URL } from "./constants";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response)
      return Promise.reject({
        response: { data: { message: "Error: Network Error" } },
      });
    else return Promise.reject(error);
  }
);

export const setBearer = (token: string) => {
    axios.defaults.headers.common = { Authorization: `bearer ${token}` };
  },
  setBaseUrl = (url: string) => {
    axios.defaults.baseURL = url;
  },
  url2data = async (url: string, params?: unknown, method?: string) => {
    let response;

    if (params) {
      if (method === "PUT") response = await axios.put(url, params);
      else if (method === "DELETE") response = await axios.delete(url);
      else response = await axios.post(url, params);
    } else response = await axios.get(url);

    return response.data;
  };

export default axios;
