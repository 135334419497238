export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.hostname.includes("localhost")
    ? "http://localhost:3000/api/"
    : "https://" + window.location.hostname + "/api/",
  CDN_URL = "https://cdn.sanity.io/",
  SURVEY_URLS = {
    "1": "https://uisnettop.eu.qualtrics.com/jfe/form/SV_1NRsjWh6aaktbKK",
    "2": "https://uisnettop.eu.qualtrics.com/jfe/form/SV_efic2WdnE7X4yai",
    "3": "https://uisnettop.eu.qualtrics.com/jfe/form/SV_d4LQ01QY1y3r2jY",
  },
  GIFT_CARD_MODULES: Record<string, number> = {
    "1": 300,
    "4": 300,
    "5": 400,
  },
  MONTH_NAMES = [
    "januar",
    "februar",
    "mars",
    "april",
    "mai",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "desember",
  ];
