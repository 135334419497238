import React from "react";
import "./main.less";

export const CoinDrop = (args: { children: React.ReactNode }) => {
  return (
    <div className="coinDrop">
      {Array.from({ length: 5 }).map((value, index) => (
        <div key={index} />
      ))}
      {args.children}
    </div>
  );
};
