import React from "react";
import "./main.less";

interface Props {
  value: number;
  complete: boolean;
  colorOverride?: string;
  className?: string;
}

export const Progress = ({
  value,
  complete,
  colorOverride,
  className,
}: Props) => (
  <div
    className={`progress ${className}`}
    style={{
      background:
        value < 100
          ? `radial-gradient(
          circle var(--at05) at calc(${value}% - var(--at05)) 50%,
          var(--atNavGra80) 100%,
          transparent 100%
        ),
        linear-gradient(
          to right,
          var(--atNavGra80) calc(${value}% - var(--at05)),
          var(${
            colorOverride || "--atNavLysBlaLighten90"
          }) calc(${value}% - var(--at05))
        )`
          : complete
          ? "var(--atNavGronn)"
          : "var(--atNavGra80)",
    }}
  />
);
