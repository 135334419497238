import React from "react";
import { useHistory } from "react-router-dom";

import "./main.less";
import { areChapters, description } from "../../../type-guards";
import { Card } from "./card/Card";
import { getProgress } from "../../../utilities";
import { GiftCard } from "../../giftCard/GiftCard";
import { Header } from "../../common/header/Header";
import { reachPage } from "../../progressSlice";
import { SanityContent } from "../../../sanity-client";
import { Section } from "../../../types";
import { setIds } from "../../contentSlice";
import { useDispatch, useSelector } from "../../../hooks";

export const Chapters = ({ list }: { list: SanityContent[] }) => {
  const { GENERAL, MOOD, PAGE } = Section,
    dispatch = useDispatch(),
    history = useHistory(),
    progress = useSelector((state) => state.progress),
    content = useSelector((state) => state.content.sanity),
    pageCounts = useSelector((state) => state.content.meta.pageCounts),
    version = useSelector((state) => state.user.version);

  return (
    (content[GENERAL] && areChapters(list) && version && (
      <>
        <Header
          theme="light"
          ancillaryButtons={true}
          title={content[GENERAL][GENERAL].title?.toUpperCase()}
          description={description(content[GENERAL][GENERAL])}
        />
        <section>
          <ol className="chapters">
            {list
              .filter(
                ({ version1 }) =>
                  (version === 1 && version1) || (version === 2 && !version1)
              )
              .sort((a, b) => a.order - b.order || 0)
              .map(({ _id, title, description, order }, index) => {
                const launchPageId =
                  order < progress.chapter ||
                  progress.completed?.indexOf(order) !== -1 ||
                  (progress.chapter === 0 && progress.pageNumber === undefined)
                    ? "initial"
                    : (progress.pageNumber !== undefined && "persisted") ||
                      MOOD;

                return (
                  <Card
                    {...{ key: _id, index, title, description }}
                    progress={
                      progress.completed?.indexOf(order) !== -1
                        ? 100
                        : getProgress(index, _id, progress, pageCounts)
                    }
                    complete={progress.completed?.indexOf(order) !== -1}
                    dropped={progress.dropped?.indexOf(order) !== -1}
                    completed={progress.completed}
                    version={version}
                    chapters={content[Section.CHAPTERS]}
                    launch={() => {
                      launchPageId !== MOOD &&
                        progress.chapter === order &&
                        progress.pageNumber === undefined &&
                        dispatch(reachPage({ chapter: order, pageNumber: 0 }));
                      dispatch(setIds({ id: launchPageId, parentId: _id }));
                      history.push(`/${launchPageId === MOOD ? MOOD : PAGE}`);
                    }}
                  />
                );
              })}
            <GiftCard compact={true} />
          </ol>
        </section>
      </>
    )) ||
    null
  );
};
