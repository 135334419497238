import { Heart } from "@navikt/ds-icons";
import React, { useRef, useEffect, SVGProps } from "react";

type Props = {
  className: string;
  viewBox: string;
  pathData: string;
} & Pick<SVGProps<SVGPathElement>, "fillRule">;

export const NavVariant = (args: Props) => {
  const { className, viewBox, pathData, fillRule = "inherit" } = args,
    svgEl = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgEl.current) {
      svgEl.current.style.display = "inherit";
      svgEl.current.getElementsByTagName("path")[0].setAttribute("d", pathData);
      svgEl.current
        .getElementsByTagName("path")[0]
        .setAttribute("fillRule", fillRule);
    }
  }, [fillRule, pathData]);

  return (
    <Heart
      ref={svgEl}
      style={{ display: "none" }}
      className={className}
      viewBox={viewBox}
    />
  );
};
