import React from "react";
import "./main.less";
import { format } from "../../../../../formatter";
import { getImageURL } from "../../../../../utilities";
import { SanityPage } from "../../../../../sanity-client";

interface Props {
  props: SanityPage;
  svgsRef: React.MutableRefObject<Record<string, HTMLImageElement>>;
}

export const Example = ({
  props: { exampleTitle: title, exampleImage: image, example, category },
  svgsRef,
}: Props) =>
  example ? (
    <section className="example">
      <p className="title">{title}</p>
      {image && (
        <div
          className="image"
          style={{
            backgroundImage: `url(${getImageURL(image.asset._ref)})`,
          }}
          title={image.caption}
        />
      )}
      {format(example, category === "Eksempel (alternativ)" ? svgsRef : true)}
    </section>
  ) : null;
