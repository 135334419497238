import React from "react";
import { ReactComponent as FemaleSide } from "../graphics/femaleside.svg";

export const Contact = () => (
  <div className="contactBox">
    <div className="svgWrapper">
      <FemaleSide />
    </div>
    <strong>Kontakt oss</strong>
    <br />
    Dersom du lurer på noe om appen kan du kontakte oss på epost:{" "}
    <a href="mailto:hjelp@rost.app">hjelp@rost.app</a>
  </div>
);
