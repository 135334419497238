import React from "react";
import { useParams } from "react-router-dom";

import { completeChapter } from "../progressSlice";
import { Header } from "../common/header/Header";
import { LinkButton } from "../common/button/Button";
import { Milestone } from "./Milestone";
import { Points } from "../common/points/Points";
import { Section } from "../../types";
import { useDispatch, useSelector } from "../../hooks";
import { usePoller } from "../view/usePoller";
import { progressService } from "../../api";

export const SurveyComplete = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const version = useSelector((state) => state.user.version);
  const completed = useSelector((state) => state.progress.completed);
  const dropped = useSelector((state) => state.progress.dropped);
  const content = useSelector((state) => state.content.sanity);

  usePoller("");

  switch (id) {
    case "1":
      if (completed?.indexOf(990) === -1 && dropped?.indexOf(990) === -1)
        progressService.put(0, "surveyCheckIn");
      return (
        <Milestone
          fullscreen={true}
          cta={<LinkButton to="/objective">Gå videre</LinkButton>}
          milestoneHeading="Bra innsats!"
          milestoneBody="Takk for at du svarte på spørsmålene!&#10;Da har du gitt et viktig bidrag til forskningsprosjektet. Vi gir deg 100 poeng som takk.&#10;Du kan nå gå videre til introduksjonen hvor vi vil fortelle deg litt om hva RØST handler om og veien videre."
          points={
            <Points
              inMilestone={true}
              headless={true}
              version={version}
              chapters={content[Section.CHAPTERS]}
              completed={completed}
            />
          }
        />
      );
    case "2":
      if (completed?.indexOf(4) === -1 && dropped?.indexOf(4) === -1)
        dispatch(completeChapter());
      return (
        <>
          <Header
            homeButton={true}
            ancillaryButtons={true}
            collapsed={true}
            milestone={true}
          />
          <Milestone
            cta={<LinkButton to="/chapters">Til forsiden</LinkButton>}
            milestoneHeading="Bra innsats!"
            milestoneBody="Takk for at du svarte på spørsmålene!&#10;Da har du gitt et viktig bidrag til forskningsprosjektet."
            points={
              <Points
                inMilestone={true}
                headless={true}
                version={version}
                chapters={content[Section.CHAPTERS]}
                completed={completed}
              />
            }
          />
        </>
      );
    case "3":
      if (completed?.indexOf(5) === -1 && dropped?.indexOf(5) === -1)
        dispatch(completeChapter());
      return (
        <>
          <Header
            homeButton={true}
            ancillaryButtons={true}
            collapsed={true}
            milestone={true}
          />
          <Milestone
            cta={<LinkButton to="/chapters">Til forsiden</LinkButton>}
            milestoneHeading="Bra innsats!"
            milestoneBody="Takk for at du svarte på spørsmålene!&#10;Du har nå fullført alle modulene i RØST.&#10;Tusen takk for dine innspill! Da har du gitt et viktig bidrag til forskningsprosjektet.&#10;Vi håper du også har fått litt hjelp av RØST.&#10;Husk at du kan gå tilbake for å se gjennom modulene."
            noPoints={
              dropped == null || dropped?.indexOf(5) !== -1 ? true : undefined
            }
            points={
              <Points
                inMilestone={true}
                headless={true}
                version={version}
                chapters={content[Section.CHAPTERS]}
                completed={completed}
              />
            }
          />
        </>
      );
  }

  return null;
};
