import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";

import { Assignment } from "../../page/body/assignment/Assignment";
import { Button } from "../../../common/button/Button";
import { getAnswers } from "../../../contentSlice";
import { getProgress } from "../../../progressSlice";
import { userService } from "../../../../api";
import { useSelector, useDispatch } from "../../../../hooks";

export const NotificationOverview = (args: { backButton: () => void }) => {
  const [savedFade, setSavedFade] = useState<null | boolean>(null),
    isLoggedIn = useSelector((state) => state.auth).user.id !== null,
    choiceOfDirection = useSelector((state) => state.user.choiceOfDirection),
    dispatch = useDispatch(),
    path = choiceOfDirection === "jobb" ? "work" : "education",
    assignmentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProgress());
      dispatch(getAnswers([`schedule__${path}_feedback`]));
    }
  }, [dispatch, isLoggedIn, path]);

  return (
    <section className="settingsPage">
      <h1>Varslinger</h1>
      {args.backButton()}
      <p>
        Vi sender deg påminnelser på SMS hver uke, når en ny del er
        tilgjengelig.
      </p>
      <p>Når på dagen passer det for deg å sette av tid til RØST?</p>
      <Assignment
        {...{ ref: assignmentRef, order: 1, map: {}, path: "" }}
        svgsRef={{ current: {} }}
        tasks={[
          {
            body: "",
            footnote: "",
            id: `schedule__${path}_feedback`,
            limit: "0",
            placeholder: "Skriv her...",
            type: "Tid",
            _type: "assignment",
            _id: "",
            _createdAt: "",
            _rev: "",
            _updatedAt: "",
          },
        ]}
      />
      <br />
      <Button
        type="hoved"
        onClick={() => {
          userService
            .addUserFeedbackData({
              [`schedule__${path}_feedback`]:
                assignmentRef.current?.querySelector("select")?.value || "",
            })
            .then(() => {
              dispatch(getAnswers([`schedule__${path}_feedback`]));
              setSavedFade(false);
              setTimeout(() => setSavedFade(true), 0);
              setTimeout(() => setSavedFade(null), 1000);
            });
        }}
        children="Lagre"
      />
      {savedFade !== null && (
        <span className={classNames("confirmation", savedFade && "fade")}>
          Lagret!
        </span>
      )}
      <p>Du vil få en påminnelse på SMS i det tidsrommet du velger.</p>
    </section>
  );
};
