import React from "react";
import "./main.less";

export const After = () => (
  <section className="after">
    <h2>Muligheter etter videregående skole</h2>
    <p>
      Det er mange muligheter når du har fullført videregående skole. Her finner
      du noen eksempler.
    </p>
    <h3>Folkehøgskole</h3>
    <p>
      Du kan bruke et år på folkehøgskole som forberedelse til videre utdanning.
      Du kan utforske interessene dine og få nye venner. Les mer om
      folkehøyskole på folkehoyskole.no.
    </p>
    <h3>Lånekassen</h3>
    <p>
      Du kan søke om{" "}
      <a href="https://lanekassen.no/nb-NO/stipend-og-lan/norge/folkehogskole/">
        lån og stipend fra Lånekassen til folkehøyskole
      </a>
      .
    </p>
    <h3>Forsvaret</h3>
    <p>
      Les om mulighetene i førstegangstjenesten og andre{" "}
      <a href="https://www.forsvaret.no/utdanning">
        utdanningsmuligheter i Forsvaret på forsvaret.no
      </a>
      .
    </p>
    <h3>Fagskoler</h3>
    <p>
      Fagskoler er yrkesrettede utdanninger som varer mellom 1/2 - 2 år. Du kan
      velge mellom helsefag, reiseliv, media, økonomi med mer. Les om{" "}
      <a href="https://utdanning.no/tema/utdanning/fagskole">
        fagskole på utdanning.no
      </a>
      .
    </p>
    <h3>Høyere utdanning</h3>
    <p>
      Har du studiekompetanse eller realkompetanse, kan du søke på treårig
      bachelorprogram eller femårig masterprogram. Elever i videregående
      opplæring har rett til rådgiving om utdannings- og yrkesmuligheter. Ta
      kontakt med yrkesrådgiveren ved skolen din.
    </p>
    <p>
      Les om om hvordan du{" "}
      <a href="https://utdanning.no/tema/soknad_og_opptak/slik_soker_du_hoyere_utdanning">
        søker på høyere utdanning på utdanning.no
      </a>
      .
    </p>
    <h3>Utdanning i utlandet</h3>
    <p>
      Hvis du studerer i utlandet, kan du lære om andre kulturer, bli bedre på
      språk og få en opplevelse for livet. Du kan ta hele eller deler av
      utdanningen i utlandet.{" "}
      <a href="https://utdanning.no/tema/hjelp_og_veiledning/utdanning_i_utlandet">
        Les mer på utdanning.no
      </a>
      .
    </p>
    <h3>Økonomisk støtte til utdanning</h3>
    <p>
      De fleste finansierer studiene ved studielån og ved å jobbe ved siden av.
    </p>
    <h3>Lånekassen</h3>
    <p>
      Du kan søke om lån og stipend fra{" "}
      <a href="https://lanekassen.no/">Lånekassen</a> til høyere utdanning eller
      annen videreutdanning.
    </p>
    <h3>Aleneforsørgere kan ha rett til skolepenger</h3>
    <p>
      Er du aleneforsørger kan du ha{" "}
      <a href="https://www.nav.no/overgangsstonad-enslig">
        rett til overgangsstønad
      </a>{" "}
      fra NAV. Du kan også ha rett på{" "}
      <a href="https://www.nav.no/tilleggsstonader">
        tilleggsstønader og stønad til skolepenger
      </a>{" "}
      hvis du trenger utdanning for å bli i stand til å forsørge deg selv.
    </p>
    <h3>Andre støtteordninger</h3>
    <p>
      Det finnes fond, stipender og legater som gir støtte til utdanning. Les
      mer om{" "}
      <a href="https://www.ung.no/stipend">
        stipend, fond og legater på ung.no
      </a>
      .
    </p>
  </section>
);
