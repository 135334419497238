import React from "react";
import { useEffect, useState } from "react";

import "./main.less";
import { isChapter } from "../../../type-guards";
import { Items } from "../../../types";
import { PointsIcon } from "../icons/Icons";
import { SanityContent } from "../../../sanity-client";

export const Points = (args: {
  inMilestone: boolean;
  headless: boolean;
  version: number | null;
  chapters: Items<SanityContent>;
  completed: number[] | undefined;
}) => {
  const { inMilestone, headless, version, chapters, completed } = args,
    startValue = 100,
    points =
      (chapters &&
        completed &&
        Object.values(chapters).reduce((sum: number, chapter) => {
          if (
            isChapter(chapter) &&
            ((version === 1 && chapter.version1) ||
              (version === 2 && !chapter.version1))
          )
            return completed?.indexOf(chapter.order) !== -1
              ? (sum += chapter.points)
              : sum;
          else return sum;
        }, startValue)) ||
      startValue,
    [previousCount, setPreviousCount] = useState(points),
    [counter, setCounter] = useState(previousCount);

  useEffect(() => {
    let start = previousCount;
    const end = points;

    if (end === undefined || start === end) return;

    let timer = setInterval(() => {
      start += 1;
      setCounter(start);
      if (start === end) {
        clearInterval(timer);
        setPreviousCount(end);
      }
    }, 1000 / (end - start));

    return () => clearInterval(timer);
  }, [points, previousCount]);

  return points === undefined ? null : headless ? (
    <>{points}</>
  ) : (
    <>
      <span>{inMilestone ? counter : points}</span>
      <PointsIcon />
    </>
  );
};
