export enum Section {
  GENERAL = "general",
  ABOUT = "about",
  CHAPTERS = "chapter",
  PAGE = "page",
  MOOD = "mood",
}

export type Items<T> = Record<string, T>;

export type Objective = "work" | "education";

export type Meta = {
  currentId?: string;
  currentParentId?: string;
  movedBack: boolean;
  pageCounts: Record<string, number>;
  raw: Record<string, string>;
  answers: string[];
  answer: string;
  lockedTasks: string[];
  autofillTexts: Record<string, string> | null;
  fading: boolean;
};

export type Progress = {
  chapter: number;
  pageNumber?: number;
  lastReportedMood?: number;
  completed?: number[];
  dropped?: number[];
  started?: number;
  moods?: number[];
  giftcards?: GiftCardData[];
  waitingForGiftcards?: boolean;
  failed?: boolean;
};

export type User = {
  id: number | null;
  version: number | null;
  choiceOfDirection: "jobb" | "utdanning" | null;
  mobile: string | null;
  qualtricsUuid: string | null;
};

export type GiftCardData = {
  value: number;
  pin: number;
  expiry: number;
  url: string;
};

export type Timeout = ReturnType<typeof setTimeout>;

export type Interval = ReturnType<typeof setInterval>;
