import sanityClient from "@sanity/client";

import {
  Chapter,
  Page,
  General,
  About,
  Illustration,
  Assignment,
  Audio,
  Video,
} from "../sanity-schema";
import { Section } from "./types";
import config from "./sanity-config.json";

export type { SanityReference } from "../sanity-schema";

type SanityMeta = {
  _id: string;
  _createdAt: string;
  _rev: string;
  _updatedAt: string;
};

export type Params = {
  name: Section | "illustration";
  id?: string;
  parentId?: string;
};

export type SanityChapter = Chapter & SanityMeta;

export type SanityPage = Page & SanityMeta;

export type SanityGeneral = General & SanityMeta;

export type SanityAbout = About & SanityMeta;

export type SanityContent =
  | SanityChapter
  | SanityPage
  | SanityGeneral
  | SanityAbout;

export type SanityIllustration = Illustration;

export type SanityAssignment = Assignment;

export type SanityAudio = Audio;

export type SanityVideo = Video;

const sanity = sanityClient(config);

export const client = ({
  name,
  id,
  parentId,
}: Params): Promise<SanityContent[]> =>
  name === "illustration"
    ? sanity.fetch("*[_type == $type && title == $title]", {
        type: name,
        title: id,
      })
    : parentId
    ? sanity.fetch("*[_type == $type] && references($parentId)", {
        type: name,
        parentId,
      })
    : !id || id === name
    ? sanity.fetch("*[_type == $type]", { type: name })
    : sanity.fetch("*[_type == $type && _id == $id]", { type: name, id });
