import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";

export const Addendum = (args: WizardContentProps) => (
  <>
    <Header homeButton={true} ancillaryButtons={true} collapsed={true} />
    <div className="addendum">
      <h2>Hvorfor spør vi deg om humøret ditt?</h2>
      <p>
        Hver uke spør vi deg om humøret ditt. Du velger selv om du vil svare.
      </p>
      <p>
        I løpet av de seks ukene du følger dette kurset, vil du bygge opp en
        oversikt over ditt humør.
      </p>
      <p>
        Du kan når som helst se oversikten over humøret ditt i instillinger.
      </p>
      {args.children()}
    </div>
  </>
);
