import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import * as guards from "../../type-guards";
import { Chapters } from "./chapters/Chapters";
import { Choice, Confirmation, Mobile } from "../../features/objective";
import { OnBoardingComplete } from "../../features/milestone/OnboardingComplete";
import { Home, Register, Onboarding } from "./general";
import { Mood, Addendum, Feedback } from "./mood";
import { PageContainer } from "./page/PageContainer";
import { SanityContent } from "../../sanity-client";
import { Section, Items } from "../../types";
import { usePoller } from "./usePoller";
import { useSelector } from "../../hooks";
import { Wizard, Props as WizardProps } from "../common/wizard/Wizard";
import { ReactComponent as TusenTakk } from "../common/graphics/tusentakk.svg";
import { Button } from "../common/button/Button";
import { SURVEY_URLS } from "../../constants";

const audioElement = document.getElementById("audioPlayer") as HTMLAudioElement,
  killAnyAudio = () => {
    audioElement.pause();
    audioElement.className = "reset";
    audioElement.querySelector("source")!.src =
      "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    audioElement.load();
  },
  { CHAPTERS, GENERAL, MOOD, PAGE } = Section,
  { RegistrationInfo, Complete } = Onboarding;
let exitConfig: WizardProps["exitConfig"] = { path: CHAPTERS },
  steps;

export const View = ({ as }: { as?: string }) => {
  const path = useLocation().pathname.slice(1),
    name = as ? as : path,
    history = useHistory(),
    { qualtricsUuid } = useSelector((state) => state.user),
    content = useSelector((state) => state.content.sanity),
    chapter = useSelector((state) => state.progress.chapter),
    choiceOfDirection = useSelector((state) => state.user.choiceOfDirection),
    mobile = useSelector((state) => state.user.mobile),
    completed = useSelector((state) => state.progress.completed),
    id = useSelector((state) => state.content.meta.currentId),
    parentId = useSelector((state) => state.content.meta.currentParentId),
    list: Items<SanityContent> | SanityContent[] =
      (guards.isSection(name) &&
        content[name] &&
        (parentId
          ? Object.values(content[name])
          : id && content[name][id]
          ? [content[name][id]]
          : Object.values(content[name]))) ||
      [],
    parent = (parentId && content[CHAPTERS]?.[parentId]) || undefined;

  useEffect(() => {
    window.scrollTo(0, 0) !== null && killAnyAudio();
  }, [id, name]);

  usePoller(name);

  switch (name) {
    case CHAPTERS:
      if (completed?.indexOf(990) === -1 && completed?.indexOf(0) === -1)
        return (
          <section className={"privacy centered"}>
            <h1>Takk for at du vil delta i RØST!</h1>
            <p>Vi setter stor pris på ditt bidrag!</p>
            <TusenTakk />
            <br />
            <p>
              Du skal nå gå videre til spørreundersøkelsen. Det er kanskje ikke
              den kjekkeste delen av RØST, men det er utrolig verdifullt for
              forskningsprosjektet at du svarer.
            </p>
            <p>
              Sett av nok tid til å gjøre deg ferdig med spørreskjemaet (ca 15
              min). Dersom du lukker spørreskjemaet før du er helt ferdig, vil
              alle svarene dine slettes og du må starte skjema på nytt for å gå
              videre.
            </p>
            <p>
              Husk at du tjener 100 poeng når du har fullført spørreskjema. Du
              har 1 uke på deg.
            </p>
            <br />
            <Button
              onClick={() =>
                (window.location.href =
                  SURVEY_URLS["1"] + "?q_uuid=" + qualtricsUuid)
              }
            >
              Fortsett til spørreundersøkelsen
            </Button>
          </section>
        );
      else if (choiceOfDirection === null && completed?.indexOf(0) === -1)
        return (
          <Wizard
            {...{
              steps: [Choice, Confirmation, Mobile, OnBoardingComplete],
              exitConfig: { path: Section.CHAPTERS },
            }}
          />
        );
      else if (mobile === null && completed?.indexOf(0) === -1)
        return (
          <Wizard
            {...{
              steps: [Mobile, OnBoardingComplete],
              exitConfig: { path: Section.CHAPTERS },
            }}
          />
        );
      else if (guards.areChapters(list)) return <Chapters list={list} />;
      break;
    case GENERAL:
      localStorage.setItem("seenSplash", "true");
      steps = [Home, Register, RegistrationInfo, Complete];
      if (list[0] && guards.isGeneral(list[0]))
        return <Wizard {...{ steps, content: list[0], exitConfig }} />;
      break;
    case MOOD:
      steps = chapter === 1 ? [Mood, Feedback, Addendum] : [Mood, Feedback];
      exitConfig = { path: PAGE, parentId, id: "initial" };
      if (parentId)
        return <Wizard {...{ steps, content: list[0], exitConfig }} />;
      break;
    case PAGE:
      if (id && parent && guards.isChapter(parent) && guards.arePages(list))
        return <PageContainer {...{ id, list, parent }} />;
  }
  name !== GENERAL && history.push("/");

  return null;
};
