import { useEffect, useState, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from "react-redux";

import type { RootState, AppDispatch } from "./store";

export const useDispatch = (): AppDispatch => _useDispatch(),
  useSelector: TypedUseSelectorHook<RootState> = _useSelector,
  useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
      changeWindowSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      };

    useEffect(() => {
      window.addEventListener("resize", changeWindowSize);

      return () => window.removeEventListener("resize", changeWindowSize);
    }, []);

    return windowSize;
  },
  useToggleKey = (targetKey: string, initial: boolean) => {
    const [boolean, setBoolean] = useState(initial);

    useEffect(() => {
      const getKey = ({ key: eventKey }: { key: string }) =>
        eventKey === targetKey && setBoolean((boolean) => !boolean);

      window.addEventListener("keypress", getKey);

      return () => window.removeEventListener("keypress", getKey);
    }, [targetKey]);

    return boolean;
  },
  useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  },
  useMemoCompare = <T>(next: T, compare: (prev: T, next: T) => boolean) => {
    const prevRef = useRef<T>(),
      prev = prevRef.current,
      isEqual = prev && compare(prev, next);

    useEffect(() => {
      if (!isEqual) prevRef.current = next;
    });

    return isEqual ? prev : next;
  };
