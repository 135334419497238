import React, { createElement, MutableRefObject, forwardRef } from "react";
import { Textarea } from "nav-frontend-skjema";
import classNames from "classnames";

import "./main.less";
import { format } from "../../../../../formatter";
import { SanityAssignment } from "../../../../../sanity-client";
import { setAnswers } from "../../../../contentSlice";
import { useAutofill } from "./useAutofill";
import { useSelector, useDispatch } from "../../../../../hooks";
import * as lookup from "./";

interface Props {
  tasks: SanityAssignment[];
  svgsRef: MutableRefObject<Record<string, HTMLImageElement>>;
  order: number;
  map: Record<string, number>;
  path: string;
}

type Ref = React.ForwardedRef<HTMLElement>;

export const Assignment = forwardRef((args: Props, ref: Ref) => {
  const lockedTasks = useSelector((state) => state.content.meta.lockedTasks),
    answers = useSelector((state) => state.content.meta.answers),
    dispatch = useDispatch();

  useAutofill(answers, args.tasks);

  return (
    <section className={classNames("assignmentBlock")} ref={ref}>
      {lockedTasks &&
        args.tasks.map(
          ({ body, type, footnote, id, placeholder, limit: _limit }, index) => {
            const locked = lockedTasks.indexOf(id) !== -1,
              limit = locked ? "0" : _limit;

            if (answers[index] === undefined) return null;

            return (
              <span key={id} className={(_limit === "560" && "big") || ""}>
                {format(body, args.svgsRef)}
                {type &&
                  type !== "Valgfri" &&
                  createElement(lookup[type], {
                    ...{ locked, index, footnote, type, answers, placeholder },
                    ...{ order: args.order, map: args.map, path: args.path },
                  })}
                <Textarea
                  {...{ id, disabled: limit === "0", placeholder }}
                  value={answers[index] === " " ? "" : answers[index]}
                  onBlur={({ target: { value } }) => {
                    const copy = answers.slice();

                    if (value === "") copy[index] = value;
                    dispatch(setAnswers(copy));
                  }}
                  onChange={({ target: { value } }) => {
                    const copy = answers.slice();

                    copy[index] = value;
                    dispatch(setAnswers(copy));
                  }}
                  {...(answers[index] === "" ? { feil: "Må fylles ut" } : {})}
                  {...(limit !== "0" && answers[index].length > parseInt(limit)
                    ? { feil: `Maks antall tegn er ${limit}` }
                    : {})}
                  maxLength={parseInt(limit)}
                />
                {footnote && format(footnote, args.svgsRef)}
              </span>
            );
          }
        )}
    </section>
  );
});
